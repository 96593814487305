const Colors = {
  HeaderBlue : '#055AA1',
  SelectedBlue : '#47A7F0',
  HeaderGreen : '#52B947',
  SelectedGreen: '#57E947',
  ContentBlue : '#0073B3',
  HeaderBackground : 'rgb(25,25,25)',
  WaveBackground1 : 'rgba(25,25,25,.7)',
  WaveBackground2 : 'rgba(25,25,25,.5)',
  WaveBackground3 : 'rgba(25,25,25,.3)',
};

export default Colors;
