import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { withStyles } from "@material-ui/core/styles";
import IconTriggerRow from "./icon-trigger-row";
import HowComponent from "./how-component";
import WhatComponent from "./what-component";
import WhoComponent from "./who-component";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import compose from "recompose/compose";
import Typography from "../../shared/Typography";
import FooterBar from "../../footer/footer";
import Colors from "../../shared/Colors";

const bg = require("../../../media/background.png");

var Scroll = require("react-scroll");
var Element = Scroll.Element;

const styles = () => ({
  root: {
    height: "auto",
    display: "flex",
    flexDirection: "column"
  },
  flexBase: {
    width: "100%",
    display: "flex",
    alignItems: "center"
  },
  flexCol: {
    flexDirection: "column"
  },
  mainContainer: {
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${bg})`,
    backgroundPosition: "center"
  },
  baseText: {
    textAlign: "center",
    width: "100%"
  },
  headerText: {
    color: `${Colors.HeaderText}`,
    textAlign: "center"
  },
  subText: {
    color: `${Colors.ContentBlue}`,
    textAlign: "center"
  },
  statement: {
    margin: "2rem 0 .5rem 0",
    width: "80%"
  }
});

class AboutComponent extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <CssBaseline />
        <Container
          name="about"
          className={classes.root}
          disableGutters={true}
          maxWidth={false}
        >
          <div className={classes.mainContainer}>
            <div className={`${classes.flexCol} ${classes.flexBase}`}>
              <Typography
                variant="h3"
                styles={`${classes.statement} ${classes.baseText} ${classes.headerText}`}
                text="Whatever the mission, Four Sea Group is ready for the challenge"
                color="Header-Blue"
                strong={true}
                margin={true}
              />
              <br />
              <IconTriggerRow />
              <Element name="who"></Element>
              {isWidthUp("md", this.props.width) ? <WhoComponent /> : null}
              <Element name="what"></Element>
              {isWidthUp("md", this.props.width) ? <WhatComponent /> : null}
              <Element name="how"></Element>
              {isWidthUp("md", this.props.width) ? <HowComponent /> : null}
            </div>
            <FooterBar />
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withWidth()
)(AboutComponent);
