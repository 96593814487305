import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import PropTypes from "prop-types";

function HowIcon(props) {
  const { children, pathProps, ...other } = props;
  return (
    <SvgIcon {...other}>
      {children}
      <div>
        Icons made by{" "}
        <a
          href="https://www.flaticon.com/authors/icongeek26"
          title="Icongeek26"
        >
          Icongeek26
        </a>{" "}
        from{" "}
        <a href="https://www.flaticon.com/" title="Flaticon">
          www.flaticon.com
        </a>
      </div>
      <path
        d="M108.087,256.444c-.029,0-.058,0-.087,0s-.058,0-.087,0a6,6,0,0,1-5.913-6,6,6,0,0,0-12,0A18.015,18.015,0,0,0,102,267.4v2.19a6,6,0,0,0,12,0V267.4a17.987,17.987,0,0,0-6-34.949,6,6,0,1,1,6-6,6,6,0,0,0,12,0,18.016,18.016,0,0,0-12-16.95v-1.644a6,6,0,0,0-12,0V209.5a17.988,17.988,0,0,0,6,34.95,6,6,0,0,1,.087,12Z"
        {...pathProps}
      />
      <path
        d="M484,236h-.793l.39-.073L409.29,80.334a5.868,5.868,0,0,0-5.415-3.267A6.674,6.674,0,0,0,399.622,79H298.373A20,20,0,0,0,279.5,66h-47a20,20,0,0,0-18.873,13H112.378a6.644,6.644,0,0,0-4.253-1.905,5.946,5.946,0,0,0-5.415,3.355L28.4,235.957l.39.043H28a6.1,6.1,0,0,0-6,6.113c0,44.277,38.636,80.356,86.125,80.356s86.125-36.107,86.125-80.384a6.073,6.073,0,0,0-6-6.085h-.793l.39.046L118.491,91H212.4a20.494,20.494,0,0,0,20.1,16H250V337.442c-36.627,2.65-65.68,29.282-67,62.072A41.018,41.018,0,0,0,149.1,439.869a6.113,6.113,0,0,0,6,6.131H356.9a6.113,6.113,0,0,0,6-6.131A40.947,40.947,0,0,0,329,399.58c-1.318-32.791-30.371-59.49-67-62.138V107h17.5a20.494,20.494,0,0,0,20.1-16h93.906L324.153,236.046l.39-.046h-.793a6.1,6.1,0,0,0-6,6.113c0,44.277,38.636,80.356,86.125,80.356S490,286.362,490,242.085A6.073,6.073,0,0,0,484,236ZM181.967,248c-3.307,35-35.162,62.3-73.842,62.3S37.59,283,34.283,248H58V236H41.612L108.125,96.677,174.638,236H158v12ZM350.275,434H161.724a29.052,29.052,0,0,1,28.194-23H322.081A29.052,29.052,0,0,1,350.275,434Zm-33.309-35H195.034c1.765-28,28.434-49.71,60.968-49.71S315.2,371,316.966,399ZM279.5,95h-47a8.5,8.5,0,0,1,0-17h47a8.5,8.5,0,0,1,0,17ZM403.875,310.3c-38.68,0-70.535-27.3-73.842-62.3H383V236H337.362L403.875,96.677,470.388,236H425v12h52.717C474.41,283,442.555,310.3,403.875,310.3Z"
        {...pathProps}
      />
      <path
        d="M108,292.762a56,56,0,1,0-56-56A56.063,56.063,0,0,0,108,292.762Zm0-100a44,44,0,1,1-44,44A44.049,44.049,0,0,1,108,192.762Z"
        {...pathProps}
      />
      <path
        d="M374.919,242.152a18.086,18.086,0,0,1,5.333,15.647l-.373,2.863a6.644,6.644,0,0,0,1.231,5.016c.236.329.89.624.89.9v12.78A13.461,13.461,0,0,0,395.21,293h17.566A13.473,13.473,0,0,0,426,279.36V266.324a8.054,8.054,0,0,0,1.988-4.932c0-.055.041-.11.038-.165l-.247-5.125c0-.011.009-.021.009-.031a17.614,17.614,0,0,1,5.275-13.9,42.579,42.579,0,0,0,13.355-30.94,42.486,42.486,0,0,0-45.1-42.4c-21.493,1.412-38.942,19.29-39.724,40.681A42.5,42.5,0,0,0,374.919,242.152ZM412.776,281H395.21c-.772,0-1.21-.867-1.21-1.64V268h20v11.36C414,280.133,413.549,281,412.776,281Zm-39.19-71.03a30.718,30.718,0,0,1,28.47-29.16,30.489,30.489,0,0,1,32.365,30.43,30.012,30.012,0,0,1-9.587,21.962A30.179,30.179,0,0,0,415.8,256H392.411a30.458,30.458,0,0,0-9.245-22.808A30.017,30.017,0,0,1,373.586,209.97Z"
        {...pathProps}
      />
    </SvgIcon>
  );
}

HowIcon.propTypes = {
  children: PropTypes.node,
  pathProps: PropTypes.object
};

export default HowIcon;
