import React, { Component } from 'react';
import './App.css';
import HeaderBar from './components/header/header';
import { withStyles } from "@material-ui/core/styles";
const styles = () => ({
  root: {
    width: '100%',
  },
});

class App extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
          <HeaderBar/>
          <div>
            {this.props.children}
          </div>
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(App);
