import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import Colors from "../shared/Colors";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  chevron: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    },
    "&:hover": {
      color: `${Colors.SelectedGreen}`,
      backgroundColor: "inherit !important"
    }
  },
  header: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      height: "100%"
    }
  },
  navItem: {
    [theme.breakpoints.up("md")]: {
      fontSize: ".75rem"
    },
    width: "auto",
    [theme.breakpoints.up("md")]: {
      color: "white"
    },
    [theme.breakpoints.down("sm")]: {
      color: "black"
    },
    "&:hover": {
      color: `${Colors.HeaderGreen}`,
      backgroundColor: "inherit !important"
    },
    transition: "color 200ms cubic-bezier(0.4, 0, 0.2, 1) 25ms"
  },
  navItemText: {
    [theme.breakpoints.up("md")]: {
      fontSize: ".9rem"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1rem"
    }
  }
});

class HeaderList extends React.Component {
  render() {
    const { classes } = this.props;

    const customListItem = (link, name) => {
      return (
        <ListItem className={classes.navItem} component="a" href={link}>
          <ListItemText
            classes={{ primary: classes.navItemText }}
            primary={name}
          />
        </ListItem>
      );
    };

    return (
      <List aria-label="header navigation" className={`${classes.header}`}>
        <div className={classes.drawerHeader}>
          <IconButton className={classes.chevron}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        {customListItem("/home", "ABOUT US")}
        {customListItem("/careers", "CAREERS")}
        {customListItem("/benefits", "BENEFITS")}
        {/* {customListItem("/news", "NEWS")} */}
        {customListItem("/contact", "CONTACT US")}
      </List>
    );
  }
}

export default withStyles(styles, { withTheme: true })(HeaderList);
