import React from "react";
// import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Typography from "../shared/Typography";
import "../../App.css";

const styles = (theme) => ({
  selectedText: {
    textAlign: "justify",
  },
  baseTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "50%",
    height: "100%",
    // margin: "0 2.5rem"
    [theme.breakpoints.up("md")]: {
      margin: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "1.5em",
    },
  },
  textContainerSelected: {
    justifyContent: "flex-start",
    width: "80%",
    // margin: "1rem 2.5rem"
  },
});

class S1 extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Typography
        variant="body1"
        styles={`${classes.subText}`}
        color="Content-Blue"
        strong={false}
        component="span"
        text={
          <span>
            At Four Sea Group, all employees have access to an Employee Stock
            Ownership Program (ESOP). Our goal is for all of our employees to
            become owners, and for all of our owners to benefit from the success
            of the company. In addition to regular compensation (including
            Profit Sharing and benefits), Four Sea Group contributes a set
            percentage of each employee’s compensation towards purchasing shares
            in the ESOP.
          </span>
        }
      />
    );
  }
}

class S2 extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Typography
        variant="body1"
        styles={`${classes.subText}`}
        color="Content-Blue"
        strong={false}
        component="span"
        text={
          <span>
            At Four Sea Group, we offer a Profit Sharing Plan (PSP) to all of
            our employees. From the perspective of our employees this account is
            designed to act and feel like a traditional 401K. Each quarter, Four
            Sea Group contributes a percentage, based on each employee’s
            compensation, into a profit sharing account in which the employee is
            immediately vested.
          </span>
        }
      />
    );
  }
}
class S3 extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Typography
        variant="body1"
        styles={`${classes.subText}`}
        color="Content-Blue"
        strong={false}
        component="span"
        text={
          <span>
            At Four Sea Group, our goal is to provide a highly customizable,
            employee-centric benefits portfolio. We achieve this by paying our
            employees a health benefits stipend that the employee can use at
            their discrection. The employee has the ability to apply this
            stipend, tax-free, to the cost of any benefits (and supplemental
            insurance and benefits) they choose, while the remainder is kept by
            the employee as taxable income. If employees choose to enroll
            directly through Four Sea Group, we offer a large variety of primary
            medical plans. We also offer access to Health Savings Accounts
            (HSAs) or Flex Spending Accounts (FSAs), dental insurance, and
            vision insurance. This allows our employees to get exactly what they
            need and keep the rest as cash.
          </span>
        }
      />
    );
  }
}

class S4 extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Typography
        variant="body1"
        styles={`${classes.subText}`}
        color="Content-Blue"
        strong={false}
        component="span"
        text={
          <span>
            We understand that work is only part of the lives of our employees,
            so as a company, we need to ensure everyone has sufficient time away
            from work. In order to do this we offer a flexible amount of Paid
            Time Off (PTO). All of our employees, at a minimum, receive 5 weeks(200 hours)
            of PTO. In addition we offer our employees the ability to bank their time and borrow against
            their time (on an annual basis) so that they can work a flexible schedule year round. 
          </span>
        }
      />
    );
  }
}

class S5 extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Typography
        variant="body1"
        styles={`${classes.subText}`}
        color="Content-Blue"
        strong={false}
        component="span"
        text={
          <span>
            In order to ensure that our employees are always at the top of their
            game, we provide company- sponsored training. Training is provided
            in the form of certification classes, formal education, and online
            training.
          </span>
        }
      />
    );
  }
}

class S6 extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Typography
        variant="body1"
        styles={`${classes.subText}`}
        color="Content-Blue"
        strong={false}
        component="span"
        text={
          <span>
            Four Sea Group is spread across many locations, so it’s important,
            every once in a while, for us to get away and relax together. This
            fosters communication and collaboration within the company. We
            sponsor regular social events, which range from weekday happy hours
            to renting a box at a Nuggets game on a Saturday night. Other events
            we’ve done are puzzle rooms, axe throwing, cooking classes, and an
            evening at Top Golf. And we also surprise all of our employees with
            a fun end of year event!
          </span>
        }
      />
    );
  }
}

const Selected1 = withStyles(styles, { withTheme: true })(S1);
const Selected2 = withStyles(styles, { withTheme: true })(S2);
const Selected3 = withStyles(styles, { withTheme: true })(S3);
const Selected4 = withStyles(styles, { withTheme: true })(S4);
const Selected5 = withStyles(styles, { withTheme: true })(S5);
const Selected6 = withStyles(styles, { withTheme: true })(S6);

export { Selected1, Selected2, Selected3, Selected4, Selected5, Selected6 };
