import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import IconButton from "@material-ui/core/IconButton";
import EmailIcon from '@material-ui/icons/Email';
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import compose from "recompose/compose";
import { Gradient1, Gradient2 } from "../shared/icon-gradient";
import Colors from "../shared/Colors";

const styles = theme => ({
  root: {
    position: "relative",
    textAlign: "center"
    // background:"white",
    // color: "white"
  },
  waves: {
    position: "relative",
    width: "100%",
    height: "5vh",
    marginBottom: "-7px" /*Fix for safari gap*/,
    [theme.breakpoints.up("md")]: {
      minHeight: "100px",
      maxHeight: "150px"
    },
    [theme.breakpoints.down("sm")]: {
      minHeight: "40px",
      maxHeight: "75px"
    }
  },
  footerContent: {
    position: "relative",
    height: "20vh",
    minHeight: "175px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  footerPrimaryColor: {
    backgroundColor: `${Colors.HeaderBackground}`
  },
  footerList: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    color: "white",
    textAlign: "justify"
  },
  navItem: {
    [theme.breakpoints.up("md")]: {
      fontSize: ".75rem"
    },
    width: "auto",
    color: "white",
    "&:hover": {
      color: "#33cc33",
      backgroundColor: "inherit !important"
    },
    transition: "color 200ms cubic-bezier(0.4, 0, 0.2, 1) 25ms"
  },
  navItemText: {
    display: "flex",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".75rem"
    },
    [theme.breakpoints.up("md")]: {
      fontSize: ".9rem"
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1rem"
    }
  },
  /* its white */
  whitegoodmantext: {
    color: "white"
  },
  contactIcon: {
    color: "white",
    "&:hover": {
      color: "#0ab03d",
      backgroundColor: "inherit !important"
    },
    transition: "color 200ms cubic-bezier(0.4, 0, 0.2, 1) 25ms"
  },
});

const gradient1 = "gradient1";
const gradient2 = "gradient2";

class FooterBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover1: false,
      hover2: false
    };
  }

  render() {
    const { classes } = this.props;
    const { hover1, hover2 } = this.state;
    let keyframes = `@-webkit-keyframes move-forever {
        0% {
          -webkit-transform: translate3d(-90px,0,0);
        }
        100% {
          -webkit-transform: translate3d(85px,0,0);
        }
    }`;

    const customListItem = (link, name) => {
      return (
        <ListItem className={classes.navItem} component="a" href={link}>
          <ListItemText
            classes={{ primary: classes.navItemText }}
            primary={name}
          />
        </ListItem>
      );
    };

    let styleSheet = document.styleSheets[0];
    styleSheet.insertRule(keyframes, styleSheet.cssRules.length);
    let style = {
      animationName: "move-forever",
      animationTimingFunction: "cubic-bezier(.55,.5,.45,.5)",
      animationDuration: "6.5s",
      animationDelay: "0.0s",
      animationIterationCount: "infinite",
      animationDirection: "normal",
      animationFillMode: "forwards"
    };
    let style2 = {
      animationName: "move-forever",
      animationTimingFunction: "cubic-bezier(.55,.5,.45,.5)",
      animationDuration: "8s",
      animationDelay: "-2s",
      animationIterationCount: "infinite",
      animationDirection: "normal",
      animationFillMode: "forwards"
    };
    let style3 = {
      animationName: "move-forever",
      animationTimingFunction: "cubic-bezier(.55,.5,.45,.5)",
      animationDuration: "9.5s",
      animationDelay: "-3s",
      animationIterationCount: "infinite",
      animationDirection: "normal",
      animationFillMode: "forwards"
    };
    let style4 = {
      animationName: "move-forever",
      animationTimingFunction: "cubic-bezier(.55,.5,.45,.5)",
      animationDuration: "11s",
      animationDelay: "-5s",
      animationIterationCount: "infinite",
      animationDirection: "normal",
      animationFillMode: "forwards"
    };
    if (isWidthDown("sm", this.props.width)) {
      style.animationDuration = "4s";
      style2.animationDuration = "6s";
      style2.animationDelay = "-1s";
      style3.animationDelay = "-1.5s";
      style3.animationDuration = "7s";
      style4.animationDelay = "-2.5s";
      style4.animationDuration = "8s";
    }
    return (
      <React.Fragment>
        <CssBaseline />
        <div className={classes.root}>
          <div>
            <svg
              className={classes.waves}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 24 150 28"
              preserveAspectRatio="none"
              shapeRendering="auto"
            >
              <defs>
                <path
                  id="gentle-wave"
                  d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                />
              </defs>
              <g>
                <use
                  style={style}
                  xlinkHref="#gentle-wave"
                  x="48"
                  y="5"
                  fill={Colors.WaveBackground1}
                />
                <use
                  style={style2}
                  xlinkHref="#gentle-wave"
                  x="48"
                  y="5"
                  fill={Colors.WaveBackground2}
                />
                <use
                  style={style3}
                  xlinkHref="#gentle-wave"
                  x="48"
                  y="5"
                  fill={Colors.WaveBackground3}
                />
                <use
                  style={style4}
                  xlinkHref="#gentle-wave"
                  x="48"
                  y="7"
                  fill={Colors.HeaderBackground}
                />
              </g>
            </svg>
          </div>
        </div>

        <div
          className={`${classes.footerContent} ${classes.footerPrimaryColor}`}
        >
          <List
            aria-label="header navigation"
            className={`${classes.footerList}`}
          >
            {customListItem("/home", "ABOUT US")}
            {customListItem("/careers", "CAREERS")}
            {customListItem("/benefits", "BENEFITS")}
            {/* {customListItem("/news", "NEWS")} */}
            {customListItem("/contact", "CONTACT US")}
          </List>
          <div className={classes.footerList}>
            {isWidthDown("sm", this.props.width) ? (
              <div>
              <IconButton
                aria-label="delete"
                className={classes.contactIcon}
                disableTouchRipple={true}
                href="https://www.linkedin.com/company/fourseagroupinc/"
                onMouseEnter={() => {
                  if (isWidthDown("sm", this.props.width)) {
                    this.setState({ hover1: true });
                  }
                }}
                onMouseLeave={() => {
                  if (isWidthDown("sm", this.props.width)) {
                    this.setState({ hover1: false });
                  }
                }}
              >
                <LinkedInIcon
                  fontSize="large"
                  component={svgProps => (
                    <svg {...svgProps}>
                      {React.cloneElement(svgProps.children[0], {
                        fill: `url(#${hover1 ? gradient2 : gradient1})`,
                      })}
                      <Gradient1 />
                      <Gradient2 />
                    </svg>
                  )}
                />
              </IconButton>
              <IconButton
              aria-label="delete"
              className={classes.contactIcon}
              disableTouchRipple={true}
              href="mailto:contact@4seagroup.com"
              onMouseEnter={() => {
                if (isWidthDown("sm", this.props.width)) {
                  this.setState({ hover2: true });
                }
              }}
              onMouseLeave={() => {
                if (isWidthDown("sm", this.props.width)) {
                  this.setState({ hover2: false });
                }
              }}
            >
              <EmailIcon
                fontSize="large"
                component={svgProps => (
                  <svg {...svgProps}>
                    {React.cloneElement(svgProps.children[0], {
                      fill: `url(#${hover2 ? gradient2 : gradient1})`,
                    })}
                    <Gradient1 />
                    <Gradient2 />
                  </svg>
                )}
              />
            </IconButton>
            </div>
            ) : null}
          </div>
          <p className={classes.whitegoodmantext}>© 2020 Four Sea Group </p>
          {/* <div>
            <p>© 2020 Four Sea Group </p>
          </div> */}
        </div>
      </React.Fragment>
    );
  }
}
export default compose(
  withStyles(styles, { withTheme: true }),
  withWidth()
)(FooterBar);
