import React from "react";
import Drawer from "@material-ui/core/Drawer";
import HeaderList from "./header-list";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import compose from "recompose/compose";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EmailIcon from '@material-ui/icons/Email';

import { Gradient1, Gradient2 } from "../shared/icon-gradient";
import Colors from "../shared/Colors";

const styles = () => ({
  icon: {
    color: "white",
    "&:hover": {
      color: `${Colors.SelectedGreen}`,
      backgroundColor: "inherit !important"
    }
  },
  list: {
    width: 250,
    height: "100%"
    // backgroundImage: 'linear-gradient(to bottom right, rgba(0, 111, 188,.5), rgba(0, 111, 188, 1))'
  },
  fullList: {
    width: "auto"
  },
  contactIcon: {
    color: "white",
    "&:hover": {
      color: `${Colors.HeaderGreen}`,
      backgroundColor: "inherit !important"
    },
    margin: "2rem 0",
    transition: "color 200ms cubic-bezier(0.4, 0, 0.2, 1) 25ms"
  },
  centerFlex: {
    display: "flex",
    flexDirection: 'row',
    justifyContent: "center",
    alignItems: "center"
  }
});

const gradient1 = "gradient1";
const gradient2 = "gradient2";

class HeaderMenu extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = { hover1: false };
    this.listenSizeEvent = () => {
      if (isWidthUp("md", this.props.width)) {
        this.setState({ ...this.state});
      }
    };
  }

  render() {
    window.addEventListener("resize", this.listenSizeEvent);
    const { hover1, hover2} = this.state;
    const { classes } = this.props;

    const toggleDrawer = (side, open) => () => {
      this.setState({ ...this.state, [side]: open });
    };

    const sideList = side => (
      <div
        className={classes.list}
        role="presentation"
        onClick={toggleDrawer(side, false)}
      >
        <HeaderList />
      </div>
    );
    return (
      <div className={classes.root}>
        <IconButton
          className={classes.icon}
          aria-label="open drawer"
          edge="start"
          onClick={toggleDrawer("right", true)}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor="right"
          open={this.state.right}
          onClose={toggleDrawer("right", false)}
          className={classes.drawer}
        >
          {sideList("right")}
          <div className={classes.centerFlex}>
            <IconButton
              aria-label="delete"
              className={classes.contactIcon}
              disableTouchRipple={true}
              href="https://www.linkedin.com/company/fourseagroupinc/"
              onMouseEnter={() => {
                this.setState({ hover1: true });
              }}
              onMouseLeave={() => {
                this.setState({ hover1: false });
              }}
            >
              <LinkedInIcon
                fontSize="large"
                component={svgProps => (
                  <svg {...svgProps}>
                    {React.cloneElement(svgProps.children[0], {
                      fill: `url(#${hover1 ? gradient2 : gradient1})`
                    })}
                    <Gradient1 />
                    <Gradient2 />
                  </svg>
                )}
              />
            </IconButton>
            <IconButton
              aria-label="delete"
              className={classes.contactIcon}
              disableTouchRipple={true}
              href="mailto:contact@fourseagroup.com"
              onMouseEnter={() => {
                this.setState({ hover2: true });
              }}
              onMouseLeave={() => {
                this.setState({ hover2: false });
              }}
            >
              <EmailIcon
                fontSize="large"
                component={svgProps => (
                  <svg {...svgProps}>
                    {React.cloneElement(svgProps.children[0], {
                      fill: `url(#${hover2 ? gradient2 : gradient1})`
                    })}
                    <Gradient1 />
                    <Gradient2 />
                  </svg>
                )}
              />
            </IconButton>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withWidth()
)(HeaderMenu);
