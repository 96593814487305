import React from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import compose from "recompose/compose";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import SchoolIcon from "@material-ui/icons/School";
import FlightIcon from "@material-ui/icons/Flight";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import LocalCafeIcon from "@material-ui/icons/LocalCafe";
// import { Gradient1 } from "../shared/icon-gradient";
import {
  Selected1,
  Selected2,
  Selected3,
  Selected4,
  Selected5,
  Selected6,
} from "./benefitscontent";

import "../../App.css";

const styles = theme => ({
  iconBtn: {
    "&:hover,&:hover $customListIcon": {
      backgroundColor: "transparent"
    }
  },
  btnLabel: {
    flexDirection: "column"
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  btnText: {
    margin: "1em",
    [theme.breakpoints.up("lg")]: {
      fontSize: ".65em"
    },
    [theme.breakpoints.down("md")]: {
      fontSize: ".6em"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".575em"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: ".5em"
    }
  },
  customListIcon: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "50px"
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "40px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px"
    },
    color: "#6a6a6a",
    display: "inline-flex",
    minWidth: "0px",
    flexShrink: "0"
  },
  gridRow: {
      margin: "1rem 0"
  },
  selectedBG: {
    // backgroundColor: "rgba(0,0,0,.15)"
    // style={{backgroundImage: 'linear-gradient(to bottom right, #439bfd, #ffffff)'}}
    //linear-gradient(to bottom right, red 0%, orange 10% 30%, yellow 50% 70%, green 90% 100%)
    backgroundImage:
      "linear-gradient(to bottom right, rgba(0,0,0,.1), transparent)"
  },
  gridContent: {
    justifyContent: "center"
  }
});

const gradient1 = "gradient1";
const gradient2 = "gradient2";

class BenefitsGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover1: false,
      hover2: false,
      hover3: false,
      hover4: false,
      hover5: false,
      hover6: false,
    };
    this.outputChange = this.outputChange.bind(this);
  }

  outputChange(val) {
    this.selected = val;
    this.props.selectedChange(this.selected);
  }

  render() {
    const {
      hover1,
      hover2,
      hover3,
      hover4,
      hover5,
      hover6,
    } = this.state;
    const selected = this.props.selected;
    const { classes } = this.props;

    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Grid container item xs={12} spacing={1} className={classes.gridRow}>
          <Grid
            item
            xs={4}
            // style={{backgroundImage: 'linear-gradient(to bottom right, #439bfd, #ffffff)'}}
            className={`${classes.gridItem} ${
              selected === 1 && isWidthDown("sm", this.props.width)
                ? classes.selectedBG
                : null
            }`}
          >
            <IconButton
              disableTouchRipple={true}
              className={classes.iconBtn}
              classes={{ label: classes.btnLabel }}
              onMouseEnter={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover1: true });
                  this.outputChange(1);
                }
              }}
              onMouseLeave={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover1: false });
                }
              }}
              onClick={() => this.outputChange(1)}
            >
              <AccountBalanceIcon
                className={classes.customListIcon}
                component={svgProps => (
                  <svg {...svgProps}>
                    {React.cloneElement(svgProps.children[0], {
                      fill: `url(#${hover1 ? gradient2 : gradient1})`
                    })}
                  </svg>
                )}
              />
              <h6 className={`${classes.btnText} ${"Header-Blue"}`}>
              Employee Stock Ownership Program
              </h6>
            </IconButton>
          </Grid>
          <Grid
            item
            xs={4}
            className={`${classes.gridItem} ${
              selected === 2 && isWidthDown("sm", this.props.width)
                ? classes.selectedBG
                : null
            }`}
          >
            <IconButton
              disableTouchRipple={true}
              className={classes.iconBtn}
              classes={{ label: classes.btnLabel }}
              onMouseEnter={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover2: true });
                  this.outputChange(2);
                }
              }}
              onMouseLeave={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover2: false });
                }
              }}
              onClick={() => this.outputChange(2)}
            >
              <MonetizationOnIcon
                className={classes.customListIcon}
                component={svgProps => (
                  <svg {...svgProps}>
                    {React.cloneElement(svgProps.children[0], {
                      fill: `url(#${hover2 ? gradient2 : gradient1})`
                    })}
                  </svg>
                )}
              />
              <h6 className={`${classes.btnText} ${"Header-Blue"}`}>
              Profit Sharing Plan
              </h6>
            </IconButton>
          </Grid>
          <Grid
            item
            xs={4}
            className={`${classes.gridItem} ${
              selected === 3 && isWidthDown("sm", this.props.width)
                ? classes.selectedBG
                : null
            }`}
          >
            <IconButton
              disableTouchRipple={true}
              className={classes.iconBtn}
              classes={{ label: classes.btnLabel }}
              onMouseEnter={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover3: true });
                  this.outputChange(3);
                }
              }}
              onMouseLeave={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover3: false });
                }
              }}
              onClick={() => this.outputChange(3)}
            >
              <LocalHospitalIcon
                className={classes.customListIcon}
                component={svgProps => (
                  <svg {...svgProps}>
                    {React.cloneElement(svgProps.children[0], {
                      fill: `url(#${hover3 ? gradient2 : gradient1})`
                    })}
                  </svg>
                )}
              />
              <h6 className={`${classes.btnText} ${"Header-Blue"}`}>
              Health Benefits
              </h6>
            </IconButton>
          </Grid>
        </Grid>
        {isWidthDown("sm", this.props.width) ? (
          <Grid
            className={classes.gridContent}
            container
            item
            xs={12}
            spacing={1}
          >
            {selected === 1 ? <Selected1 /> : null}
            {selected === 2 ? <Selected2 /> : null}
            {selected === 3 ? <Selected3 /> : null}
          </Grid>
        ) : null}
        <Grid container item xs={12} spacing={1} className={classes.gridRow}>
          <Grid
            item
            xs={4}
            className={`${classes.gridItem} ${
              selected === 4 && isWidthDown("sm", this.props.width)
                ? classes.selectedBG
                : null
            }`}
          >
            <IconButton
              disableTouchRipple={true}
              className={classes.iconBtn}
              classes={{ label: classes.btnLabel }}
              onMouseEnter={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover4: true });
                  this.outputChange(4);
                }
              }}
              onMouseLeave={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover4: false });
                }
              }}
              onClick={() => this.outputChange(4)}
            >
              <FlightIcon
                className={classes.customListIcon}
                component={svgProps => (
                  <svg {...svgProps}>
                    {React.cloneElement(svgProps.children[0], {
                      fill: `url(#${hover4 ? gradient2 : gradient1})`
                    })}
                  </svg>
                )}
              />
              <h6 className={`${classes.btnText} ${"Header-Blue"}`}>
              Paid Time Off
              </h6>
            </IconButton>
          </Grid>
          <Grid
            item
            xs={4}
            className={`${classes.gridItem} ${
              selected === 5 && isWidthDown("sm", this.props.width)
                ? classes.selectedBG
                : null
            }`}
          >
            <IconButton
              disableTouchRipple={true}
              className={classes.iconBtn}
              classes={{ label: classes.btnLabel }}
              onMouseEnter={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover5: true });
                  this.outputChange(5);
                }
              }}
              onMouseLeave={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover5: false });
                }
              }}
              onClick={() => this.outputChange(5)}
            >
              <SchoolIcon
                className={classes.customListIcon}
                component={svgProps => (
                  <svg {...svgProps}>
                    {React.cloneElement(svgProps.children[0], {
                      fill: `url(#${hover5 ? gradient2 : gradient1})`
                    })}
                  </svg>
                )}
              />
              <h6 className={`${classes.btnText} ${"Header-Blue"}`}>
              Regular Employee Training
              </h6>
            </IconButton>
          </Grid>
          <Grid
            item
            xs={4}
            className={`${classes.gridItem} ${
              selected === 6 && isWidthDown("sm", this.props.width)
                ? classes.selectedBG
                : null
            }`}
          >
            <IconButton
              disableTouchRipple={true}
              className={classes.iconBtn}
              classes={{ label: classes.btnLabel }}
              onMouseEnter={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover6: true });
                  this.outputChange(6);
                }
              }}
              onMouseLeave={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover6: false });
                }
              }}
              onClick={() => this.outputChange(6)}
            >
              <LocalCafeIcon
                className={classes.customListIcon}
                component={svgProps => (
                  <svg {...svgProps}>
                    {React.cloneElement(svgProps.children[0], {
                      fill: `url(#${hover6 ? gradient2 : gradient1})`
                    })}
                  </svg>
                )}
              />
              <h6 className={`${classes.btnText} ${"Header-Blue"}`}>
              Company Sponsored Events
              </h6>
            </IconButton>
          </Grid>
        </Grid>
        {isWidthDown("sm", this.props.width) ? (
          <Grid
            className={classes.gridContent}
            container
            item
            xs={12}
            spacing={1}
          >
            {selected === 4 ? <Selected4 /> : null}
            {selected === 5 ? <Selected5 /> : null}
            {selected === 6 ? <Selected6 /> : null}
          </Grid>
        ) : null}
      </Grid>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withWidth()
)(BenefitsGrid);
