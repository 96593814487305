import React from "react";
import WhatIcon from "../../shared/what-icon";
import WhoIcon from "../../shared/who-icon";
import HowIcon from "../../shared/how-icon";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import compose from "recompose/compose";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import HowComponent from "./how-component";
import WhatComponent from "./what-component";
import WhoComponent from "./who-component";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Gradient1, Gradient2 } from "../../shared/icon-gradient";
import Icon from '@material-ui/core/Icon';

import "./animations.css";
import * as Scroll from "react-scroll";

let Element = Scroll.Element;
let scroller = Scroll.scroller;

const styles = theme => ({
  headerText: {
    paddingLeft: "1rem",
    color: "#3973ac",
    textAlign: "center",
    [theme.breakpoints.up("lg")]: { fontSize: ".9em" },
    [theme.breakpoints.down("md")]: { fontSize: ".8em" },
    [theme.breakpoints.down("sm")]: { fontSize: ".7em", marginBottom: "0" },
    [theme.breakpoints.down("xs")]: { fontSize: ".6em", marginBottom: "0" }
  },
  flexBase: {
    width: "100%",
    display: "flex",
    alignItems: "center"
  },
  centerIt: {
    justifyContent: "center"
  },
  buttonContainer: {
    marginTop: "1em",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row"
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  btnRoot: {
    "&:hover": {
      backgroundColor: "inherit !important"
    }
  },
  btnLabel: {
    flexDirection: "column"
  },
  iconRoot: {
    [theme.breakpoints.up("lg")]: {
      height: "5.5em",
      width: "5.5em",
      margin: "0 50px"
    },
    [theme.breakpoints.down("md")]: {
      height: "5em",
      width: "5em",
      margin: "0 40px"
    },
    [theme.breakpoints.down("sm")]: {
      height: "4.5em",
      width: "4.5em",
      margin: "0 25px"
    },
    [theme.breakpoints.down("xs")]: {
      height: "4em",
      width: "4em",
      margin: "0 5px"
    },
    color: "black"
  },
  customChevronIcon: {
    color: "#3973ac",
    mozTransition: "all .1s linear",
    webkitTransition: "all .1s linear",
    transition: "all .1s linear"
  },
  chevronSpin: {
    mozTransform: "rotate(90deg)",
    webkitTransform: "rotate(90deg)",
    transform: "rotate(90deg)"
  },
  chevronRoot: {
    padding: "0"
  }
});

const gradient1 = "gradient1";
const gradient2 = "gradient2";

class IconTriggerRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover1: false,
      hover2: false,
      hover3: false,
      selected1: true,
      selected2: true,
      selected3: true
    };
  }

  render() {
    const {
      hover1,
      hover2,
      hover3,
      selected1,
      selected2,
      selected3
    } = this.state;
    const { classes } = this.props;
    const scrollToAnchor = anc => {
      scroller.scrollTo(anc, {
        duration: 1000,
        delay: 100,
        isDynamic: true,
        smooth: "easeInQuad",
        offset: -50
      });
    };

    return (
      <div
        className={`${classes.buttonContainer} ${classes.flexBase} ${classes.centerIt}`}
      >
        <IconButton
          disableTouchRipple={true}
          className={classes.btnRoot}
          id="whoBtn"
          classes={{ label: classes.btnLabel }}
          onClick={() => {
            if (isWidthUp("md", this.props.width)) {
              scrollToAnchor("who");
            } else if (isWidthDown("sm", this.props.width)) {
              // if (!selected1) {
              //   scroller.scrollTo("whoElement", {
              //     duration: 1000,
              //     delay: 100,
              //     smooth: true,
              //     offset: 20,
              //     isDynamic: true
              //   });
              // }
              this.setState({
                selected1: !selected1,
                // selected2: false,
                // selected3: false
              });
            }
          }}
        >
          <WhoIcon
            classes={{ root: classes.iconRoot }}
            viewBox="0 0 500 500"
            pathProps={{
              fill: `url(#${hover1 ? gradient2 : gradient1})`
            }}
            onMouseEnter={() => {
              if (isWidthUp("md", this.props.width)) {
                this.setState({ hover1: true });
              }
            }}
            onMouseLeave={() => {
              if (isWidthUp("md", this.props.width)) {
                this.setState({ hover1: false });
              }
            }}
          ></WhoIcon>
          <Element name="whoElement"></Element>
          <h5 className={classes.headerText}>
            Who We Are{" "}
            {isWidthDown("sm", this.props.width) ? (
              <Icon
                classes={{ root: classes.chevronRoot }}
              >
                <ChevronRightIcon
                  className={`${classes.customChevronIcon} ${
                    selected1 ? classes.chevronSpin : null
                  }`}
                />
              </Icon>
            ) : null}
          </h5>
        </IconButton>
        {isWidthDown("sm", this.props.width) && selected1 ? (
          <WhoComponent />
        ) : null}
        <Element name="whatElement"></Element>
        <IconButton
          disableTouchRipple={true}
          className={classes.btnRoot}
          id="whatBtn"
          classes={{ label: classes.btnLabel }}
          onClick={() => {
            if (isWidthUp("md", this.props.width)) {
              scrollToAnchor("what", selected1, false);
            } else if (isWidthDown("sm", this.props.width)) {
              // if (!selected2) {
              //   scroller.scrollTo("whatElement", {
              //     duration: 1000,
              //     delay: 100,
              //     smooth: true,
              //     offset: 20,
              //     isDynamic: true
              //   });
              // }
              this.setState({
                // selected1: false,
                selected2: !selected2,
                // selected3: false
              });
            }
          }}
        >
          <WhatIcon
            classes={{ root: classes.iconRoot }}
            viewBox="0 0 500 500"
            pathProps={{
              fill: `url(#${hover2 ? gradient2 : gradient1})`
            }}
            onMouseEnter={() => {
              if (isWidthUp("md", this.props.width)) {
                this.setState({ hover2: true });
              }
            }}
            onMouseLeave={() => {
              if (isWidthUp("md", this.props.width)) {
                this.setState({ hover2: false });
              }
            }}
          ></WhatIcon>
          <h5 className={classes.headerText}>
            What We Do{" "}
            {isWidthDown("sm", this.props.width) ? (
              <Icon
                classes={{ root: classes.chevronRoot }}
              >
                <ChevronRightIcon
                  className={`${classes.customChevronIcon} ${
                    selected2 ? classes.chevronSpin : null
                  }`}
                />
              </Icon>
            ) : null}
          </h5>
        </IconButton>
        {isWidthDown("sm", this.props.width) && selected2 ? (
          <WhatComponent />
        ) : null}
        <Element name="howElement"></Element>
        <IconButton
          disableTouchRipple={true}
          className={classes.btnRoot}
          id="howBtn"
          classes={{ label: classes.btnLabel }}
          onClick={() => {
            if (isWidthUp("md", this.props.width)) {
              scrollToAnchor("how", selected1, false);
            } else if (isWidthDown("sm", this.props.width)) {
              // if (!selected3) {
              //   scroller.scrollTo("howElement", {
              //     duration: 1000,
              //     delay: 100,
              //     smooth: true,
              //     offset: 20,
              //     isDynamic: true
              //   });
              // }
              this.setState({
                // selected1: false,
                // selected2: false,
                selected3: !selected3
              });
            }
          }}
        >
          <HowIcon
            classes={{ root: classes.iconRoot }}
            viewBox="0 0 500 500"
            pathProps={{
              fill: `url(#${hover3 ? gradient2 : gradient1})`
            }}
            onMouseEnter={() => {
              if (isWidthUp("md", this.props.width)) {
                this.setState({ hover3: true });
              }
            }}
            onMouseLeave={() => {
              if (isWidthUp("md", this.props.width)) {
                this.setState({ hover3: false });
              }
            }}
          >
            <Gradient1 />
            <Gradient2 />
          </HowIcon>
          <h5 className={classes.headerText}>
            How We Do It{" "}
            {isWidthDown("sm", this.props.width) ? (
              <Icon
                classes={{ root: classes.chevronRoot }}
              >
                <ChevronRightIcon
                  className={`${classes.customChevronIcon} ${
                    selected3 ? classes.chevronSpin : null
                  }`}
                />
              </Icon>
            ) : null}
          </h5>
        </IconButton>
        {isWidthDown("sm", this.props.width) && selected3 ? (
          <HowComponent />
        ) : null}
      </div>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withWidth()
)(IconTriggerRow);
