import React from "react";
// import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Typography from "../../shared/Typography";
import "../../../App.css";

const styles = theme => ({
  selectedText: {
    textAlign: "justify"
  },
  baseTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "50%",
    height: "100%",
    // margin: "0 2.5rem"
    [theme.breakpoints.up("md")]: {
      margin: "auto"
    },
    [theme.breakpoints.down("sm")]: {
      margin: "1.5em"
    }
  },
  textContainerSelected: {
    justifyContent: "flex-start",
    width: "80%"
    // margin: "1rem 2.5rem"
  },
});

class S1 extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Typography
        variant="body1"
        styles={`${classes.subText}`}
        color="Content-Blue"
        strong={false}
        component="span"
        text={
          <span>
            Four Sea Group specializes in Software Development. Our engineers
            have experience in numerous technology stacks and are capable of
            facing the toughest software challenges. At Four Sea Group we
            specialize in:
            <br />
            <ul>
              <li>Agile Software Development</li>
              <li>Full Stack Development</li>
              <li>Machine Learning and Artificial Intelligence</li>
              <li>Legacy System Modernization</li>
              <li>Data Pipeline Implementation and Data Processing</li>
            </ul>
          </span>
        }
      />
    );
  }
}

class S2 extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Typography
        variant="body1"
        styles={`${classes.subText}`}
        color="Content-Blue"
        strong={false}
        component="span"
        text={
          <span>
            Four Sea Group, Inc. provides full service support to management,
            including: <br />
            <ul>
              <li>Process improvement:</li>
              <ul>
                <li>Schedule compression</li>
                <li>Repeatable success</li>
                <li>Cost efficiencies</li>
              </ul>
              <li>
                Bridging the gap between technical, business and management
                disciplines
              </li>
              <li>
                Identifying and correcting discrepancies between customer and
                contractor baselines
              </li>
            </ul>
          </span>
        }
      />
    );
  }
}
class S3 extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Typography
        variant="body1"
        styles={`${classes.subText}`}
        color="Content-Blue"
        strong={false}
        component="span"
        text={
          <span>
            Four Sea Group has extensive expertise in the field of cyber
            security. With numerous engineers certified by COMPTia and
            ISC&#178;, Four Sea Group is always ready to identify, mitigate, and
            prevent threats to our customers. Our engineers specialize in:
            <ul>
              <li>User, Data, and Edge Protection</li>
              <li>Cross Domain Solutions</li>
              <li>Vulenerability Identification and Mitigation</li>
              <li>Network and Communication Security</li>
            </ul>
          </span>
        }
      />
    );
  }
}

class S4 extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Typography
        variant="body1"
        styles={`${classes.subText}`}
        color="Content-Blue"
        strong={false}
        component="span"
        text={
          <span>
            Four Sea Group, Inc. is a leader in algorithm design, analysis and
            improvement including the fields of:
            <br />
            <ul>
              <li>Big Data Clustering</li>
              <li>High-Performance Data Processing</li>
              <li>Heuristic Measurement Estimation</li>
              <li>High-throughput data pipeline design</li>
              <li>Multi-source data fusion</li>
              <li>Geolocation</li>
            </ul>
          </span>
        }
      />
    );
  }
}

class S5 extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Typography
        variant="body1"
        styles={`${classes.subText}`}
        color="Content-Blue"
        strong={false}
        component="span"
        text={
          <span>
            Four Sea Group, Inc. is ready to help our customers improve code and
            system coverage while reducing the overall cost of the testing
            process. Our engineers have written custom software test
            capabilities and are also experts in commercial software automation
            test suites.
          </span>
        }
      />
    );
  }
}

class S6 extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Typography
        variant="body1"
        styles={`${classes.subText}`}
        color="Content-Blue"
        strong={false}
        component="span"
        text={
          <span>
            Four Sea Group, Inc. excels at analyzing a wide variety of data,
            specializing in:
            <br />
            <ul>
              <li>
                Creation of novel analysis techniques targeting key system
                performance parameters
              </li>
              <li>Fault isolation</li>
              <li>In-depth system performance analysis</li>
              <li>Bias identification and mitigation</li>
              <li>Full-system optimization and adaptation</li>
            </ul>
          </span>
        }
      />
    );
  }
}

class S7 extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Typography
        variant="body1"
        styles={`${classes.subText}`}
        color="Content-Blue"
        strong={false}
        component="span"
        text={
          <span>
            At Four Sea Group, Inc. we have vast expertise in systems
            engineering. Our engineers are experts in all phases of the system
            development life cycle. We have experience designing, building, and
            delivering products for all of our customers. Our engineers are
            experts in the following domains:
            <ul>
              <li>Model Based Engineering</li>
              <li>Requirement Derivation</li>
              <li>Interface Design, Modeling, and Analysis</li>
              <li>System Integration</li>
            </ul>
          </span>
        }
      />
    );
  }
}

class S8 extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Typography
        variant="body1"
        styles={`${classes.subText}`}
        color="Content-Blue"
        strong={false}
        component="span"
        text={
          <span>
            At Four Sea Group, Inc. we are ready to help our customers with
            their DevOps needs. We are DevOps experts and we are able to help
            our customers transition from legacy design and delivery approaches
            to solutions using a modern DevOps pipeline. Our engineers are
            experts at:
            <ul>
              <li>Transitioning Legacy Software Environments</li>
              <li>Pipeline Design and Implementation</li>
              <li>Continuous Integration</li>
              <li>Containerization</li>
            </ul>
          </span>
        }
      />
    );
  }
}

class S9 extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Typography
        variant="body1"
        styles={`${classes.subText}`}
        color="Content-Blue"
        strong={false}
        component="span"
        text={
          <span>
            At Four Sea Group, Inc. we have expert software engineers and expert
            user interface / user experience engineers. Our engineers are
            capable of taking an idea and turning it into reality. Having
            expertise in a variety of technology stacks, front end devleopment
            technologies, and human computer interaction our engineers are ready
            to design, implement, and refine user facing software to ensure that
            we can make our customers dream product a reality.
          </span>
        }
      />
    );
  }
}

const Selected1 = withStyles(styles, { withTheme: true })(S1);
const Selected2 = withStyles(styles, { withTheme: true })(S2);
const Selected3 = withStyles(styles, { withTheme: true })(S3);
const Selected4 = withStyles(styles, { withTheme: true })(S4);
const Selected5 = withStyles(styles, { withTheme: true })(S5);
const Selected6 = withStyles(styles, { withTheme: true })(S6);
const Selected7 = withStyles(styles, { withTheme: true })(S7);
const Selected8 = withStyles(styles, { withTheme: true })(S8);
const Selected9 = withStyles(styles, { withTheme: true })(S9);

export {
  Selected1,
  Selected2,
  Selected3,
  Selected4,
  Selected5,
  Selected6,
  Selected7,
  Selected8,
  Selected9
};
