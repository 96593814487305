import React from "react";
import FooterBar from "../footer/footer";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import FloatingFooter from "../footer/floating-footer";
// import Typography from "@material-ui/core/Typography";
import Typography from "../shared/Typography";

import "../../App.css";

const mainImg = require("../../media/earth2.jpg");
const teamImg = require("../../media/team.jpg");
const workImg = require("../../media/tech2.jpg");
const bg = require("../../media/background.png");

const styles = theme => ({
  baseDiv: {
    display: "flex",
    flexDirection: "column",
    height: "auto",
    width: "100%"
  },
  mainContainer: {
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${bg})`,
    backgroundPosition: "center",
  },
  bodyText: {
    textAlign: "justify"
  },
  centered: {
    justifyContent: "center",
    alignItems: "center"
  },
  start: {
    justifyContent: "flex-start",
    alignItems: "center"
  },
  mobileImgWrap: {
    marginTop: "1rem"
  },
  footer: {
    // justifyContent: "flex-end",
    width: "100%",
    marginTop: "2rem"
  },
  mainImg: {
    backgroundImage: `url(${mainImg})`,
    height: "75vh",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      backgroundPosition: "initial"
    },
    [theme.breakpoints.down("sm")]: {
      backgroundPosition: "center"
    },
    backgroundSize: "cover",
    MozBackgroundSize: "cover",
    WebkitBackgroundSize: "cover"
  },
  mainContent: {
    textAlign: "center",
    width: "80%",
    paddingTop: "2rem"
  },
  headerContent: {
    height: "40%",
    width: "85%",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    // paddingTop: "5rem",
    color: "white"
  },
  introText: {
    backgroundColor: "rgba(0,0,0,.4)"
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    // height: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  rowImg: {
    [theme.breakpoints.up("xl")]: {
      width: "65%"
    },
    [theme.breakpoints.down("lg")]: {
      width: "80%"
    },
    [theme.breakpoints.down("md")]: {
      width: "90%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%"
    },
    [theme.breakpoints.down("xs")]: {
      width: "95%"
    }
  },
  rowChild: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("md")]: {
      width: "50%"
    },
    minWidth: "50%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "justify"
  }
});

class Careers extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { classes } = this.props;
    const cultureText = (
      <span>
        At Four Sea Group, our culture defines us. Having happy, healthy,
        employees that are satisfied with the work they do and the compensation
        they get for it is more than just a tagline - it’s what drives everything
        we do. With an ESOP, our employees are partial owners in the company. We
        succeed as a team, which means we are all doing our best to excel as
        individuals, but also making sure other teammates have what they need to
        thrive. We collaborate to bring in new work, recruit new talent, and
        make strategic decisions on how to shape our growing company. The
        customers who collaborate with us on to their contracts get access to a pool of
        talent that is dedicated to making their goals a reality and earning
        more work by continuing to build the excellent reputation of our
        workers. We have a team that is unified by our shared goals, our
        commitment to excellence, and our strong work ethic.
        <br />
        {isWidthDown("sm", this.props.width) ? (
          <div className={`${classes.mobileImgWrap} ${classes.rowChild}`}>
            <img alt="" className={classes.rowImg} src={teamImg} />
          </div>
        ) : null}
        <br />
        We pride ourselves on the culture we have created. Four Sea Group is a
        small engineering company with a small company culture. As
        owners/employees of Four Sea Group, our voices, insights and experience
        help guide the future direction of the company.
      </span>
    );
    const peopleText = (
      <span>
        At Four Sea Group, Inc., we consider our people to be some of the most
        talented in their respective domains of expertise. Our employees are
        versatile in their abilities, innovative, and driven to provide stellar
        support to our partners’ missions. We are a company that is made up of
        veterans, thought leaders in the industry, PhD educated engineers, and
        nationally recognized Subject Matter Experts. Our strength is that our
        incredible people can deliver, no matter the mission!
        <br />
        <br />
        At Four Sea Group we want to solve our customer’s challenges of
        tomorrow. We are always ready to take on the most difficult work because
        we know we can provide our customers with innovative, cost-effective
        solutions. From providing research opportunities to work on active
        mission systems, we work hard to offer our employees the ability to
        apply themselves to work that truly excites them.
        <br />
      </span>
    );
    return (
      <div className={`${classes.baseDiv}`}>
        <div
          className={`${classes.baseDiv} ${classes.mainImg} ${classes.centered}`}
        >
          <div className={`${classes.headerContent} ${classes.baseDiv}`}>
            <Typography
              variant="h1"
              styles={`${classes.introText}`}
              text="We Define Excellence"
              color="white"
              strong={true}
              margin={false}
            />
          </div>
        </div>
        <div
          className={`${classes.baseDiv} ${classes.mainContainer} ${classes.start}`}
        >
          <div className={classes.mainContent}>
            <Typography
              variant="h4"
              styles=""
              text="Ready to Join the Group"
              color="Header-Blue"
              strong={true}
              margin={true}
            />
            <div className={classes.flexRow}>
              <div className={`${classes.flexRow}`}>
                <Typography
                  variant="body1"
                  styles={`${classes.bodyText}`}
                  color="Content-Blue"
                  strong={false}
                  text={
                    <span>
                      If you are interested in joining Four Sea Group, reach out to us 
                      on <a href="https://www.linkedin.com/company/fourseagroupinc/" target="_blank" rel="noreferrer">LinkedIn</a> or
                      apply <a href="https://ats.rippling.com/fsg/jobs" target="_blank" rel="noreferrer">here</a>. 
                      We are always looking for new and amazing candidates! We would be happy
                      to chat with you about the current opportunities we have at our company!
                      <br />
                    </span>
                  }
                />
              </div>
            </div>
          </div>
          <div className={classes.mainContent}>
            <Typography
              variant="h4"
              styles=""
              text="Our Culture"
              color="Header-Blue"
              strong={true}
              margin={true}
            />
            <div className={classes.flexRow}>
              <div className={`${classes.rowChild}`}>
                <Typography
                  variant="body1"
                  styles={`${classes.bodyText}`}
                  color="Content-Blue"
                  strong={false}
                  text={cultureText}
                />
              </div>
              {isWidthUp("md", this.props.width) ? (
                <div className={`${classes.rowChild}`}>
                  <img alt="" className={classes.rowImg} src={teamImg} />
                </div>
              ) : null}
            </div>
          </div>
          <div className={classes.mainContent}>
            <Typography
              variant="h4"
              styles=""
              text="Our People Drive Innovation"
              color="Header-Blue"
              strong={true}
              margin={true}
            />
            <div className={classes.flexRow}>
              {isWidthUp("md", this.props.width) ? (
                <div className={` ${classes.rowChild}`}>
                  <img alt="" className={classes.rowImg} src={workImg} />
                </div>
              ) : null}
              <div className={`${classes.textDiv} ${classes.rowChild}`}>
                <Typography
                  variant="body1"
                  styles={`${classes.bodyText}`}
                  color="Content-Blue"
                  strong={false}
                  text={peopleText}
                />
              </div>
            </div>
            {isWidthDown("sm", this.props.width) ? (
              <div className={`${classes.flexRow} ${classes.mobileImgWrap}`}>
                <div className={` ${classes.rowChild}`}>
                  <img alt="" className={classes.rowImg} src={workImg} />
                </div>
              </div>
            ) : null}
          </div>
          <div className={`${classes.footer}`}>
            <FooterBar />
            <FloatingFooter />
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withWidth()
)(Careers);
