import React from "react";
// import { Parallax } from "react-parallax";
import Intro from "./main-components/intro";
import AboutComponent from "./main-components/about-component";
// import FooterBar from "../footer/footer";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import FloatingFooter from "../footer/floating-footer";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./carousel.css";

const bg1 = require("../../media/wave-bg-grad.jpg");
const bg2 = require("../../media/space-bg-grad.jpg");
const bg3 = require("../../media/earth.jpg");

const styles = () => ({
  root: {
    height: "1000px"
  },
  container: {
    width: "100%"
  },
  flexBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    height: "100%"
  },
  fullHeight: {
    height: "100%"
  },
  bg: {
    height: "75vh",
    width: "100%",
    backgroundSize: "cover",
    MozBackgroundSize: "cover",
    WebkitBackgroundSize: "cover"
  },
  bg1: {
    backgroundImage: `url(${bg1})`
  },
  bg2: {
    backgroundImage: `url(${bg2})`
  },
  bg3: {
    backgroundImage: `url(${bg3})`
  }
});


class MainPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Carousel
          showThumbs={false}
          showStatus={false}
          showArrows={isWidthUp("md", this.props.width) ? true : false}
          autoPlay={true}
          infiniteLoop={true}
          interval={5000}
          // stopOnHover={true}
        >
          <div className={`${classes.bg} ${classes.bg1}`}>
            <Intro num={0}></Intro>
          </div>
          <div className={`${classes.bg} ${classes.bg2}`}>
            <Intro num={1}></Intro>
          </div>
          <div className={`${classes.bg} ${classes.bg3}`}>
            <Intro num={2}></Intro>
          </div>
        </Carousel>
        <AboutComponent />
        {/* <FooterBar /> */}
        {/* <Parallax
          blur={{ min: -15, max: 15 }}
          bgImage={require("../../media/cyber-lock.jpg")}
          strength={-100}
          style={{
            height: "100vh",
            minHeight: "900px"
          }}
          bgImageStyle={{
            backgroundSize: "cover",
            MozBackgroundSize: "cover",
            WebkitBackgroundSize: "cover"
          }}
        >
          <div className={classes.flexBox}>
            <News />
            <FooterBar />
          </div>
        </Parallax> */}
        
        <FloatingFooter />
      </div>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withWidth()
)(MainPage);
