import React from "react";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import withWidth from "@material-ui/core/withWidth";
import compose from "recompose/compose";
import HeaderList from "./header-list";
import HeaderMenu from "./header-menu";
import Colors from "../shared/Colors";

import "../../App.css";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  appBar: {
    transition: "background-color 500ms cubic-bezier(0.4, 0, 0.2, 1) 25ms",
    height: "60px"
    // backgroundColor: "rgba(100,100,100,.88)",
    // shadow: "box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);"
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  },
  menuButton: {
    marginRight: "2em"
  },
  logoContainer: {
    position: "absolute",
    [theme.breakpoints.up("md")]: {
      left: "3rem",
    },
    [theme.breakpoints.down("sm")]: {
      left: "1rem",
      top: "1rem"
    },
    [theme.breakpoints.down("sm")]: {
      left: "1rem",
      top: ".75rem"
    },
    // top: "-5px"
  },
  logo: {
    [theme.breakpoints.up("md")]: {
      height: "40px",
      width: "230px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "40px",
      width: "230px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "40px",
      width: "230px",
    },
  },
  mainList: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  menu: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    },
    position: "absolute",
    right: "1rem"
  },
  toolBarRoot: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    height: "100%"
  }
});

class HeaderBar extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = !window.location.pathname.includes("/404")
      ? { color: "inherit", shadow: "none" }
      : {
          color: `${Colors.HeaderBackground}`,
          shadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
        };
    this.listenScrollEvent = () => {
      if (window.scrollY > 75 || window.pageYOffset > 75 || window.location.pathname.includes("/404")) {
        this.setState({
          color: `${Colors.HeaderBackground}`,
          shadow:
            "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)"
        });
      } else {
        this.setState({ color: "inherit", shadow: "none" });
      }
      // }
    };
  }

  render() {
    window.addEventListener("scroll", this.listenScrollEvent);
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar
          position="fixed"
          elevation={classes.elevation}
          className={classes.appBar}
          style={{
            backgroundColor: this.state.color,
            boxShadow: this.state.shadow
          }}
        >
          <Toolbar classes={{ root: classes.toolBarRoot }}>
            <Container fixed className={classes.header}>
              <a className={classes.logoContainer} href="/home">
                <img
                  alt="logo"
                  src={require("../../media/logo1.png")}
                  className={classes.logo}
                />
              </a>
              <div className={classes.mainList}>
                <HeaderList />
              </div>
              <div className={classes.menu}>
                <HeaderMenu />
              </div>
            </Container>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withWidth()
)(HeaderBar);
