import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import App from "./App";
import MainPage from "./components/main/main";
import Careers from "./components/careers/careers";
import Contact from "./components/contact/contact";
// import News from "./components/news/news";
import Benefits from "./components/benefits/benefits";
import NotFound from "./components/error/404.js";

// News Imports
// import A101519 from "./components/shared/news/articles/a101519";
// import A110919 from "./components/shared/news/articles/a110919";
// import A122019 from "./components/shared/news/articles/a122019";

// import 
const MyRoutes = () => (
  <App>
    <Routes>
      <Route exact path="/" element={<MainPage/>} />
      <Route exact path="/home" element={<MainPage/>} />
      <Route exact path="/careers" element={<Careers/>} />
      <Route exact path="/benefits" element={<Benefits/>} />
      <Route exact path="/contact" element={<Contact/>} />
      <Route exact path="/404" element={<NotFound/>} />
      {/*<Route
        path="/time*"
        element={() => {
          global.window && (global.window.location.href = "https://time.fourseagroupinc.com ");
          return null;
        }}
      />*/}
      {/*<Route
        path="/login*"
        element={() => {
          global.window && (global.window.location.href = "https://time.fourseagroupinc.com ");
          return null;
        }}
      />*/}
      <Route exact path="/time" element={
        <RedirectPage path="https://fourseagroupinc.unanet.biz/" />
      } />
      <Route render={() => <Navigate to="/404" />} />
      {/* <Route exact path="/*" Navigate to="/404"/> */}
      {/* <Route exact path="/news" element={News} /> */}

      {/* <Route exact path="/news/101519" element={A101519}/> */}
      {/* <Route exact path="/news/110919" element={A110919}/> */}
      {/* <Route exact path="/news/122019" element={A122019}/> */}
      {/* <Route exact path="/*" element={MainPage} /> */}
    </Routes>
  </App>
);

const RedirectPage = (props) => {
  useEffect(() => {
    setTimeout(() => {
      window.location.replace(props.path);
    }, 1000);
  }, [])

  return <div id="redirect_container">
    <h2 id="redirect">Redirecting to {props.path}</h2>
  </div>
};

export default MyRoutes;
