import React from "react";
import FooterBar from "../footer/footer";
import withWidth from "@material-ui/core/withWidth";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import FloatingFooter from "../footer/floating-footer";
// import Typography from "@material-ui/core/Typography";
// import { typography } from "material-ui/styles";
import Typography from "../shared/Typography";

const img = require("../../media/cyber-lock.jpg");
const bg = require("../../media/background.png");

const styles = theme => ({
  baseDiv: {
    display: "flex",
    flexDirection: "column",
    height: "auto",
    width: "100%"
  },
  mainContainer: {
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${bg})`,
    backgroundPosition: "center",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
    [theme.breakpoints.down("xs")]: {
      transform: "scale(0.6)"
    }
  },
  centered: {
    justifyContent: "center",
    alignItems: "center"
  },
  start: {
    justifyContent: "flex-start",
    alignItems: "center"
  },
  footer: {
    // justifyContent: "flex-end"
    width: "100%"
  },
  mainImg: {
    backgroundImage: `url(${img})`,
    height: "50vh",
    width: "100%",
    backgroundPosition: "center",
    backgroundSize: "cover",
    MozBackgroundSize: "cover",
    WebkitBackgroundSize: "cover"
  },
  mainContent: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  textContent: {
    width: "80%",
    maxWidth: "1200px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "4rem 0"
  },
  headerContent: {
    height: "40%",
    alignItems: "center",
    justifyContent: "center",
    color: "white"
  },
  introText: {
    width: "80%",
    textAlign: "center"
  },
  contactText: {
    textAlign: "center",
    marginBottom: "2rem"
  }
});

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { classes } = this.props;
    const bodyText = (
      <span>
        If you are interested in joining us or if you are looking for talent and
        need our support please apply on Rippling or reach out to us on
        LinkedIn.
        <br />
        <br />
        We Look Forward to Hearing From You!
        <br />
        <br />
        <a href="https://ats.rippling.com/fsg/jobs" target="_blank" rel="noreferrer">Apply to Four Sea Group Job Openings</a>
        <br />
        <a href="https://www.linkedin.com/company/fourseagroupinc/" target="_blank" rel="noreferrer">
          Message us on LinkedIn
        </a>
      </span>
    );
    return (
      <div className={`${classes.baseDiv}`}>
        <div
          className={`${classes.baseDiv} ${classes.mainImg} ${classes.centered}`}
        >
          <div className={`${classes.headerContent} ${classes.baseDiv}`}>
            <Typography
              variant="h1"
              styles={`${classes.introText}`}
              text="Contact Us!"
              color="white"
              strong={true}
              margin={false}
            />
            {/* <Typography
              variant="body1"
              styles={`${classes.headerText}`}
              color="Content-Blue"
              strong={false}
              text={cultureText}
            /> */}
          </div>
        </div>
        <div
          className={`${classes.baseDiv} ${classes.mainContainer} ${classes.mainContent} ${classes.start}`}
        >
          <div className={classes.textContent}>
            <Typography
              variant="h4"
              styles={`${classes.introText}`}
              text=" We Are Here to Help and We Are Always Interested"
              color="Header-Blue"
              strong={true}
              margin={true}
            />
            <Typography
              variant="body1"
              styles={`${classes.contactText}`}
              text={bodyText}
              color="Content-Blue"
              strong={false}
              margin={false}
            />
          </div>
          <div className={`${classes.footer}`}>
            <FooterBar />
            <FloatingFooter />
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withWidth()
)(Contact);
