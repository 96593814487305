import React from "react";
import Colors from "../shared/Colors";

const gradient1 = "gradient1";
const gradient2 = "gradient2";

const Gradient1 = () => {
    return (
      <defs>
        <linearGradient id={gradient1}>
          <stop offset="30%" stopColor={Colors.HeaderBlue} />
          <stop offset="70%" stopColor={Colors.HeaderGreen} />
        </linearGradient>
      </defs>
    );
  };
  
  const Gradient2 = () => {
    return (
      <defs>
        <linearGradient id={gradient2}>
          <stop offset="30%" stopColor={Colors.SelectedBlue} />
          <stop offset="70%" stopColor={Colors.SelectedGreen} />
        </linearGradient>
      </defs>
    );
  };

  export {
    Gradient1,
    Gradient2
  };
  
