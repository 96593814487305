import React from "react";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  h1: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "2.55rem"
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "2.35rem"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.7rem"
    }
  },
  h2: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "2.25rem"
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "2.2rem"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.1rem"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.0rem"
    }
  },
  h3: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "2rem"
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.9rem"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.8rem"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.7rem"
    }
  },
  h3m: {
    margin: "1.5rem 0"
  },
  h4: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.7rem"
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.625rem"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.4rem"
    }
  },
  h4m: {
    margin: "1.25rem 0"
  },
  h4m_mobile: {
    [theme.breakpoints.up("md")]: {
      margin: "1.25rem 0"
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 0 1.25rem 0"
    }
  },
  h5: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.5rem"
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.35rem"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.05rem"
    }
  },
  h5m: {
    margin: "1rem 0"
  },
  h6: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.35rem"
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.25rem"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.175rem"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.125rem"
    }
  },
  body1: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.15rem"
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.05rem"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.0rem"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: ".95rem"
    }
  },
  body2: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "1rem"
    },
    [theme.breakpoints.down("md")]: {
      fontSize: ".925rem"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".875rem"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: ".825rem"
    }
  },
  white: {
    color: "white"
  },
  black: {
    color: "black"
  }
});

class FSTypography extends React.Component {
//   constructor(props) {
//     super(props);
//   }

  render() {
    const { classes } = this.props;
    const text = this.props.text;
    const variant = this.props.variant;
    const color = this.props.color;
    const styles = this.props.styles;
    const isStrong = this.props.strong;
    const margin = this.props.margin;
    const mobileReduceMargin = this.props.mobileReduceMargin;
    let selectedColor = "";
    let selectedVariantClass = "";
    let selectedMargin = "";
    let component = this.props.component;
    // Set Color
    let colors = {
      white: classes.white,
      "Header-Blue": "Header-Blue",
      "Content-Blue": "Content-Blue"
    };
    // Set Variant and associated class
    let variants = {
      h1: classes.h1,
      h2: classes.h2,
      h3: classes.h3,
      h4: classes.h4,
      h5: classes.h5,
      body1: classes.body1,
      body2: classes.body2
    };
    // Set margin if selected
    let margins = {
      h1: classes.h1m,
      h2: classes.h2m,
      h3: classes.h3m,
      h4: classes.h4m,
      h4_mobile: classes.h4m_mobile,
      h5: classes.h5m,
      body1: classes.b1m,
      body2: classes.b2m
    };
    if (margin) {
      selectedMargin = margins[variant] || "";
    }
    if (mobileReduceMargin) {
      selectedMargin = margins[variant + "_mobile"] || "";
    }
    if (!component){
        component="p"
    }
    selectedVariantClass = variants[variant] || classes.body1;
    selectedColor = colors[color] || classes.white;

    return (
      <Typography
        variant={variant}
        component={component}
        className={`${selectedVariantClass} ${selectedColor} ${styles} ${selectedMargin}`}
      >
        {isStrong ? <strong>{text}</strong> : text}
      </Typography>
    );
  }
}

export default compose(withStyles(styles, { withTheme: true }))(FSTypography);
