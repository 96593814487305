import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import JobsIcon from '@material-ui/icons/ListAlt';
import withWidth from "@material-ui/core/withWidth";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Gradient1, Gradient2 } from "../shared/icon-gradient";

var Scroll = require("react-scroll");
var scroller = Scroll.animateScroll;

const styles = theme => ({
  subheader: {
    backgroundColor: theme.palette.background.paper
  },
  appBar: {
    top: "auto",
    bottom: 0,
    backgroundColor: "transparent",
  },
  grow: {
    flexGrow: 1
  },
  contactIcon: {
    color: "white",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
    padding: ".25em",
    backgroundColor: "rgba(0,0,0,.65)",
    "&:hover": {
      color: "#0ab03d",
      backgroundColor: "rgba(0,0,0,.65)"
    },
    // transition: "color 200ms cubic-bezier(0.4, 0, 0.2, 1) 25ms"
    transition: "all .15s linear"
  },
  outOfPosition: {
    position: "absolute",
    bottom: "1em",
    right: "-2em"
  },
  postion1: {
    position: "absolute",
    bottom: "1em",
    right: "2em"
  },
  postion2: {
    position: "absolute",
    bottom: "1em",
    right: "5em"
  },
  postion3: {
    position: "absolute",
    bottom: "1em",
    right: "8em"
  }
});

// const gradient1 = "gradient1";
const gradient2 = "gradient2";

class FloatingFooter extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = { scrolling: false, hover1: false, hover2: false };
    this.listenScrollEvent = () => {
      if (window.scrollY > 75) {
        this.setState({
          scrolling: true
        });
      } else {
        this.setState({ scrolling: false });
      }
    };
  }

  render() {
    const { classes } = this.props;
    const scrolling = this.state.scrolling;
    const hover1 = this.state.hover1;
    const hover2 = this.state.hover2;
    const hover3 = this.state.hover3;
    window.addEventListener("scroll", this.listenScrollEvent);

    return (
      <AppBar
        position="fixed"
        className={classes.appBar}
        style={{
          boxShadow: "none",
          maxHeight: "50px"
        }}
      >
        <Toolbar>
          <div className={classes.grow}>
            <IconButton
              disableTouchRipple={true}
              className={`${classes.contactIcon} ${
                scrolling ? classes.postion3 : classes.postion2
              }`}
              href="https://www.linkedin.com/company/fourseagroupinc/"
              target="_blank"
              onMouseEnter={() => {
                this.setState({ hover1: true });
              }}
              onMouseLeave={() => {
                this.setState({ hover1: false });
              }}
            >
              <LinkedInIcon
                fontSize="large"
                component={svgProps => (
                  <svg {...svgProps}>
                    {React.cloneElement(svgProps.children[0], {
                      fill: `${hover1 ? `url(#${gradient2})` : "#ffffff"}`
                    })}
                  </svg>
                )}
              ></LinkedInIcon>
            </IconButton>
            <IconButton
              disableTouchRipple={true}
              className={`${classes.contactIcon} ${
                scrolling ? classes.postion2 : classes.postion1
              }`}
              href="https://ats.rippling.com/fsg/jobs"
              target="_blank"
              onMouseEnter={() => {
                this.setState({ hover2: true });
              }}
              onMouseLeave={() => {
                this.setState({ hover2: false });
              }}
            >
              <JobsIcon
                fontSize="large"
                component={svgProps => (
                  <svg {...svgProps}>
                    {React.cloneElement(svgProps.children[0], {
                      fill: `${hover2 ? `url(#${gradient2})` : "#ffffff"}`
                    })}
                  </svg>
                )}
              ></JobsIcon>
            </IconButton>
            <IconButton
              className={`${classes.contactIcon} ${
                scrolling ? classes.postion1 : classes.outOfPosition
              }`}
              disableTouchRipple={true}
              onClick={() => {
                scroller.scrollToTop();
              }}
              onMouseEnter={() => {
                this.setState({ hover3: true });
              }}
              onMouseLeave={() => {
                this.setState({ hover3: false });
              }}
            >
              <KeyboardArrowUpIcon
                fontSize="large"
                component={svgProps => (
                  <svg {...svgProps}>
                    {React.cloneElement(svgProps.children[0], {
                      fill: `${hover3 ? `url(#${gradient2})` : "#ffffff"}`
                    })}
                    <Gradient1 />
                    <Gradient2 />
                  </svg>
                )}
              ></KeyboardArrowUpIcon>
            </IconButton>
          </div>
        </Toolbar>
        {/* <Gradient2 /> */}
      </AppBar>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withWidth()
)(FloatingFooter);
