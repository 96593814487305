import React from "react";
import FooterBar from "../footer/footer";
import withWidth from "@material-ui/core/withWidth";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import FloatingFooter from "../footer/floating-footer";
// import Typography from "@material-ui/core/Typography";
// import { typography } from "material-ui/styles";
import Typography from "../shared/Typography";

const styles = theme => ({
  fullHeight: {
    height: "100vh"
  },
  autoHeight: {
    height: "auto",
  },
  baseDiv: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
    [theme.breakpoints.down("xs")]: {
      transform: "scale(0.6)"
    }
  },
  centered: {
    justifyContent: "center",
    alignItems: "center"
  },
  footer: {
    justifyContent: "flex-end"
  },
  mainContent: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  textContent: {
    width: "80%",
    maxWidth: "1200px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  headerContent: {
    height: "40%",
    alignItems: "center",
    justifyContent: "center",
    color: "white"
  },
  introText: {
    width: "80%",
    textAlign: "center"
  },
  contactText: {
    textAlign: "center",
    marginBottom: "2rem"
  }
});

class NotFound extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={`${classes.baseDiv} ${classes.fullHeight}`}>
        <div className={classes.mainContent}>
          <div className={classes.textContent}>
          <Typography
              variant="h1"
              styles={`${classes.introText}`}
              text="404"
              color="Header-Blue"
              strong={true}
              margin={true}
            />
            <Typography
              variant="body1"
              styles={`${classes.introText}`}
              text="The page you are looking for couldn't be found!"
              color="Content-Blue"
              strong={true}
              margin={true}
            />
          </div>
        </div>
        <div className={`${classes.baseDiv} ${classes.footer} ${classes.autoHeight}`}>
          <FooterBar />
          <FloatingFooter />
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withWidth()
)(NotFound);
