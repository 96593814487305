import React from "react";
import { withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import compose from "recompose/compose";
import "../../../App.css";
import Typography from "../../shared/Typography";

const collab = require("../../../media/team.jpeg");

const styles = theme => ({
  flexBase: {
    width: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  flexRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  headerRow: {
    [theme.breakpoints.up("lg")]: {
      marginBottom: "2rem"
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "1.5rem"
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1rem"
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: ".5rem"
    }
  },
  headerText: {
    textAlign: "center"
  },
  subText: {
    textAlign: "justify"
  },
  section: {
    [theme.breakpoints.up("lg")]: {
      margin: "5rem 0 2.5rem 0"
    },
    [theme.breakpoints.down("md")]: {
      margin: "3rem 0 1rem 0"
    },
    [theme.breakpoints.down("sm")]: {
      margin: "1rem 0"
    }
  },
  sectionImg: {
    [theme.breakpoints.up("lg")]: {
      width: "80%"
    },
    [theme.breakpoints.down("md")]: {
      width: "90%"
    }
  },
  baseTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "50%",
    height: "100%",
    margin: "0 2.5rem"
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  textContainerDefault: {
    justifyContent: "center",
    [theme.breakpoints.up("lg")]: {
      margin: "0 1.25rem"
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      margin: "0 .5rem"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 .25rem"
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "0 .05rem"
    }
  }
});

class WhoComponent extends React.Component {

  render() {
    const { classes } = this.props;
    const subText = (
      <span>
        Four Sea Group, Inc. was founded in 2012 by a veteran of the
        aerospace industry. Based in Denver, Colorado, we provide technical
        mission support to meet the needs of a wide range of companies, from
        start-ups to multinational corporations.
        <br />
        <br />
        Our mission is to provide innovative support to our customers and bring
        comprehensive solutions to their missions. Our employees enjoy
        industry-leading opportunities to become visionary leaders, providing
        optimized and customized solutions to our partners.
      </span>
    );
    return (
      <div className={`${classes.section} ${classes.flexBase}`}>
        <div className={`${classes.flexRow} ${classes.headerRow}`}>
          <Typography
            variant="h4"
            styles={classes.headerText}
            text="Employee-Driven. Employee Ownership."
            color="Header-Blue"
            strong={true}
            margin={true}
            mobileReduceMargin={true}
          />
        </div>
        <div className={classes.flexRow}>
          <div
            className={`${classes.baseTextContainer} ${classes.textContainerDefault}`}
          >
            <Typography
              variant="body1"
              styles={`${classes.subText}`}
              color="Content-Blue"
              strong={false}
              text={subText}
            />
          </div>
          {isWidthUp("md", this.props.width) ? (
            <div
              className={`${classes.imageContainer} ${classes.textContainerDefault}`}
            >
              <img alt="" src={collab} className={classes.sectionImg} />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withWidth()
)(WhoComponent);
