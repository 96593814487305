import React from "react";
import FooterBar from "../footer/footer";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import FloatingFooter from "../footer/floating-footer";
// import Typography from "@material-ui/core/Typography";
import Typography from "../shared/Typography";
import BenefitsGrid from "./benefitsgrid";
import {
  Selected1,
  Selected2,
  Selected3,
  Selected4,
  Selected5,
  Selected6
} from "./benefitscontent";

import "../../App.css";

const bg = require("../../media/background.png");
const mainImg = require("../../media/mtnsrm.jpg");

const styles = theme => ({
  baseDiv: {
    display: "flex",
    flexDirection: "column",
    height: "auto",
    width: "100%"
  },
  mainContainer: {
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${bg})`,
    backgroundPosition: "center",
  },
  headerText: {
    textAlign: "center"
  },
  centered: {
    justifyContent: "center",
    alignItems: "center"
  },
  start: {
    justifyContent: "flex-start",
    alignItems: "center"
  },
  footer: {
    // justifyContent: "flex-end"
    width: "100%"
  },
  mainImg: {
    backgroundImage: `url(${mainImg})`,
    height: "75vh",
    width: "100%",
    backgroundPosition: "center",
    backgroundSize: "cover",
    MozBackgroundSize: "cover",
    WebkitBackgroundSize: "cover"
  },
  mainContent: {
    textAlign: "center",
    width: "80%",
    padding: "2rem 0"
  },
  headerContent: {
    height: "40%",
    width: "85%",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    // paddingTop: "5rem",
    color: "white"
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "flex-start"
  },
  flexChild: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    [theme.breakpoints.up("md")]: {
      width: "50%"
    },
    minWidth: "50%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "justify"
  },
  colChild: {
    flexDirection: "column"
  },
  rowChild: {
    flexDirection: "row"
  },
  gridContent: {
    paddingTop: "2rem",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      minHeight: "0"
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "450px"
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "0"
    },
  }
});

class Benefits extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      selected: 1
    };
    this.handleSelectedChange = this.handleSelectedChange.bind(this);
  }

  handleSelectedChange(selected) {
    this.setState({ selected: selected });
  }

  render() {
    const { classes } = this.props;
    const selected = this.state.selected;
    const selectedText = {
      1: "Employee Stock Ownership Program",
      2: "Profit Sharing Plan",
      3: "Health Benefits",
      4: "Paid Time Off",
      5: "Regular Employee Training",
      6: "Company Sponsored Events"
    };
    const cultureText = (
      <span>
        At Four Sea Group our employees enjoy world class benefits. Our benefits
        package was designed to provide our employees with the flexibility they
        need in order to be happy and healthy at work and outside of work.
      </span>
    );
    return (
      <div className={`${classes.baseDiv}`}>
        <div
          className={`${classes.baseDiv} ${classes.mainImg} ${classes.centered}`}
        >
          <div className={`${classes.headerContent} ${classes.baseDiv}`}>
            <Typography
              variant="h1"
              styles=""
              text="The Benefits"
              color="white"
              strong={true}
              margin={false}
            />
          </div>
        </div>
        <div className={`${classes.baseDiv} ${classes.mainContainer} ${classes.start}`}>
          <div className={classes.mainContent}>
            <Typography
              variant="h4"
              styles=""
              text="Industry Leading Benefits That Actually Lead The Industry"
              color="Header-Blue"
              strong={true}
              margin={true}
            />
            <Typography
              variant="body1"
              styles={`${classes.headerText}`}
              color="Content-Blue"
              strong={false}
              text={cultureText}
            />
          </div>

          <div className={classes.mainContent}>
            <div className={classes.flexRow}>
              <div className={`${classes.rowChild} ${classes.flexChild}`}>
                <BenefitsGrid
                  selected={selected}
                  selectedChange={this.handleSelectedChange}
                />
              </div>
              {isWidthUp("md", this.props.width) ? (
                <div
                  className={`${classes.colChild} ${classes.flexChild} ${classes.gridContent}`}
                >
                  <div>
                    <Typography
                      variant="h5"
                      styles=""
                      text={selectedText[selected]}
                      color="Header-Blue"
                      strong={true}
                      margin={true}
                    />
                  </div>
                  <div>
                    {selected === 1 ? <Selected1 /> : null}
                    {selected === 2 ? <Selected2 /> : null}
                    {selected === 3 ? <Selected3 /> : null}
                    {selected === 4 ? <Selected4 /> : null}
                    {selected === 5 ? <Selected5 /> : null}
                    {selected === 6 ? <Selected6 /> : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className={`${classes.footer}`}>
          <FooterBar />
          <FloatingFooter />
        </div>
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withWidth()
)(Benefits);
