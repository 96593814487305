import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
// import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import compose from "recompose/compose";
import Typography from "../../shared/Typography";
const styles = theme => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  content: {
    // padding: "3% 30%",
    // paddingTop: '10%',
    height: "100%"
  },
  intoInfo: {
    // backgroundColor: 'rgba(255,255,255, .4)',
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "100%"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "80%",
    maxWidth: "750px",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row"
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "auto"
    }
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "80%",
    maxWidth: "750px"
  },
  hireButton: {
    borderRadius: 25,
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.3rem",
      margin: "1rem"
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1.15rem",
      margin: "1rem"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".85rem",
      margin: ".25rem"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: ".75rem",
      margin: ".125rem"
    }
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
    [theme.breakpoints.down("xs")]: {
      transform: "scale(0.6)"
    }
  },
  introText: {
    color: "white",
    margin: ".5rem 0",
    textAlign: "center"
  }
});

const myTheme = createTheme({ palette: { primary: { main: "#ffffff" } } });

class Intro extends React.Component {
  constructor(props) {
    super(props);
    this.num = this.props.num;
  }

  render() {
    const { classes } = this.props;
    const bull = <span className={classes.bullet}>•</span>;

    const intros = [
      {
        title: (
          <span>
            Engineering {isWidthDown("sm", this.props.width) ? <br /> : bull}{" "}
            Excellence {isWidthDown("sm", this.props.width) ? <br /> : bull}{" "}
            Delivered
          </span>
        ),
        description: "Making the Impossible Possible"
      },
      {
        title: "A Trusted Partner",
        description:
          "We partner industry leading experts, combat veterans, and thought leaders with our customers and empower them for success." // We deliver exceptional results to empower our warfighters. "
      },
      {
        title: "Solutions that Prepare Us for Tommorow",
        description:
          "We are trained, versed, and ready to help our customers take on their missions of the future."
      }
    ];

    return (
      <React.Fragment>
        <CssBaseline />
        <Container
          name="intro"
          className={classes.root}
          disableGutters={true}
          maxWidth={false}
        >
          <div name="intro-info" className={classes.content}>
            <div className={classes.intoInfo}>
              <div className={classes.flexRow}>
                <Typography
                  variant="h1"
                  styles={classes.introText}
                  text={intros[this.num].title}
                  color="white"
                  strong={true}
                />
              </div>
              <div className={classes.flexRow}>
                <Typography
                  variant="h5"
                  styles={classes.introText}
                  text={intros[this.num].description}
                  color="white"
                  strong={false}
                />
              </div>
              <div className={classes.buttonContainer}>
                <MuiThemeProvider theme={myTheme}>
                  <Button
                    className={classes.hireButton}
                    variant="outlined"
                    color="primary"
                    href="/careers"
                  >
                    Looking to Join?
                  </Button>
                  {/* <Button
                    className={classes.hireButton}
                    variant="outlined"
                    color="primary"
                    href="/contact"
                  >
                    Looking for Talent?
                  </Button> */}
                </MuiThemeProvider>
              </div>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withWidth()
)(Intro);
