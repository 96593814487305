import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ExpertiseGrid from "./expertise-grid";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import compose from "recompose/compose";
import Typography from "../../shared/Typography";
import {
  Selected1,
  Selected2,
  Selected3,
  Selected4,
  Selected5,
  Selected6,
  Selected7,
  Selected8,
  Selected9
} from "./expertise-content";

import "../../../App.css";

const styles = theme => ({
  flexBase: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  flexRow: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center"
  },
  selectedText: {
    textAlign: "start"
  },
  centeredText: {
    textAlign: "center"
  },
  section: {
    [theme.breakpoints.up("md")]: {
      margin: "7.5rem 0 2.5rem 0"
    },
    [theme.breakpoints.down("sm")]: {
      margin: "1rem 0"
    },
    width: "80%"
  },
  baseTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "50%",
    height: "100%",
    margin: "0 2.5rem"
  },
  textContainerDefault: {
    justifyContent: "center",
    width: "50%",
    // margin: "auto",
    [theme.breakpoints.up("md")]: {
      width: "50%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  expertiseContent: {
    marginTop: "2rem"
  },
  textContainerSelected: {
    justifyContent: "flex-start",
    width: "80%",
    margin: "1rem 2.5rem"
  },
  headerRow: {
    [theme.breakpoints.up("lg")]: {
      marginBottom: "2rem"
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "1.5rem"
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "1rem"
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: ".5rem"
    }
  }
});

class WhatComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 1
    };
    this.handleSelectedChange = this.handleSelectedChange.bind(this);
  }

  handleSelectedChange(selected) {
    this.setState({ selected: selected });
  }

  render() {
    const { classes } = this.props;
    const selected = this.state.selected;

    const selectedText = {
      1: "Software Engineering",
      2: "Leadership",
      3: "Cybersecurity",
      4: "Algorithm Design",
      5: "Test and Automation",
      6: "Data Analysis",
      7: "Systems Engineering",
      8: "DevOps",
      9: "UI Design"
    };
    return (
      <div className={`${classes.section} ${classes.flexBase}`}>
        <div className={`${classes.flexRow} ${classes.headerRow}`}>
          <Typography
            variant="h4"
            styles=""
            text="Our Expertise"
            color="Header-Blue"
            strong={true}
            margin={true}
            mobileReduceMargin={true}
          />
        </div>
        <div className={classes.flexRow}>
          <div className={classes.textContainerDefault}>
            <ExpertiseGrid
              selected={selected}
              selectedChange={this.handleSelectedChange}
            />
          </div>
          {isWidthUp("md", this.props.width) ? (
            <div
              className={`${classes.expertiseContent} ${classes.textContainerDefault}`}
            >
              <div className={classes.centeredText}>
                <Typography
                  variant="h5"
                  styles=""
                  text={selectedText[selected]}
                  color="Header-Blue"
                  strong={true}
                  margin={true}
                />
              </div>
              <div>
                {selected === 1 ? <Selected1 /> : null}
                {selected === 2 ? <Selected2 /> : null}
                {selected === 3 ? <Selected3 /> : null}
                {selected === 4 ? <Selected4 /> : null}
                {selected === 5 ? <Selected5 /> : null}
                {selected === 6 ? <Selected6 /> : null}
                {selected === 7 ? <Selected7 /> : null}
                {selected === 8 ? <Selected8 /> : null}
                {selected === 9 ? <Selected9 /> : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withWidth()
)(WhatComponent);
