import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import PropTypes from "prop-types";

function WhatIcon(props) {
  const { children, pathProps, ...other } = props;
  return (
    <SvgIcon {...other}>
      {children}
      <div>
        Icons made by{" "}
        <a
          href="https://www.flaticon.com/authors/icongeek26"
          title="Icongeek26"
        >
          Icongeek26
        </a>{" "}
        from{" "}
        <a href="https://www.flaticon.com/" title="Flaticon">
          www.flaticon.com
        </a>
      </div>
      <path
        d="M345.039,54.842A85.736,85.736,0,0,0,280.6,31.463c-43.577,2.862-78.952,39.091-80.535,82.459A86.226,86.226,0,0,0,227.1,180.113,46.871,46.871,0,0,1,240.986,220.6l-.848,6.5a7.158,7.158,0,0,0,1,5.695A8.7,8.7,0,0,0,244,235.3v31.727C244,279.293,254.448,289,266.718,289h38.574c12.27,0,22.708-9.707,22.708-21.978v-32.04c0-.393,1.154-.9,1.735-1.506a7.876,7.876,0,0,0,2.1-5.73c0-.057-.061-.112-.064-.169l-.621-11.255c0-.01-.015-.019-.016-.03-.8-13.913,4.232-27.1,13.81-36.157,1.2-1.129,2.384-2.312,3.524-3.516a86.159,86.159,0,0,0-3.427-121.777ZM256,236h60v14H256Zm49.292,41H266.718c-5.654,0-10.718-4.324-10.718-9.978V262h60v5.022C316,272.676,310.946,277,305.292,277Zm50.02-133.57a73.6,73.6,0,0,1-15.563,24.939c-.981,1.035-2,2.053-3.047,3.036-12.164,11.5-18.565,28.127-17.565,45.574l.369,7.021H252.649l.228-1.773a58.9,58.9,0,0,0-17.526-50.822,74.235,74.235,0,0,1-23.291-57.024c1.361-37.316,31.815-68.479,69.28-70.94a74.173,74.173,0,0,1,73.972,99.989Z"
        {...pathProps}
      />
      <path
        d="M316.172,117H298.815l-.47-48.607a6.1,6.1,0,0,0-4.808-5.912,5.97,5.97,0,0,0-6.695,3.447l-36.518,84.1a5.6,5.6,0,0,0,.487,5.472,5.815,5.815,0,0,0,5.017,2.5h17.357l.466,48.628a6.111,6.111,0,0,0,4.808,5.923,6.177,6.177,0,0,0,1.194.169,5.978,5.978,0,0,0,5.5-3.587l36.522-84.114a5.641,5.641,0,0,0-.487-5.5A5.833,5.833,0,0,0,316.172,117Zm-30.793,61.686-.252-26.456a6.254,6.254,0,0,0-6-6.23H264.973l21.646-49.667.254,26.448a6.244,6.244,0,0,0,6,6.219h14.153Z"
        {...pathProps}
      />
      <path
        d="M147.4,311.772l-12.407,11.045a42.616,42.616,0,0,1-15.687,8.849l-6.929-22.573A5.863,5.863,0,0,0,106.634,305h-60a5.759,5.759,0,0,0-5.956,6.491l20,164.118A6.1,6.1,0,0,0,66.634,481h90a6.227,6.227,0,0,0,5.739-7.985l-11.736-38.534,36.546-23.124a21.753,21.753,0,0,1,15.853-2.056c.125.031.25.054.376.076l74.906,13.791a97.373,97.373,0,0,0,60.154-8.5,5.925,5.925,0,0,0,.734-.431l129.557-88.956a6,6,0,0,0,1.744-8.041,35.974,35.974,0,0,0-47.969-13.118l-1.911,1.031c-9.074-10.772-24.593-14.9-36.439-8.9-.045.022-.09.046-.134.069L379.092,299a40.027,40.027,0,0,0-45.59-4.089L305.525,310H268.272a53.406,53.406,0,0,1-23.178-5.184l-5.138-2.421a88.572,88.572,0,0,0-92.178,9.033C147.646,311.527,147.519,311.663,147.4,311.772ZM71.946,469,53.41,317h48.78l46.341,152ZM389.673,306.934c6.48-3.24,14.589-.709,19.956,4.157L361.02,337.314l-2.456,1.295a43,43,0,0,0-6.335-11.5Zm-50.37-1.387.143-.08a27.981,27.981,0,0,1,27.832-.1l-24.093,12.982a42.847,42.847,0,0,0-14.827-6.852Zm-104.572,7.714,5.138,2.358a65.478,65.478,0,0,0,28.4,6.381H318.1a31.213,31.213,0,0,1,30.4,24.869l-35.589-1.548a217.059,217.059,0,0,0-39.38,1.851,6.012,6.012,0,0,0,1.645,11.911,204.389,204.389,0,0,1,37.193-1.714l42.5,1.926c.091,0,.181.007.272.007a6,6,0,0,0,6-6c0-.823-.028-1.639-.074-2.451l5.592-2.949,61.569-33.215a23.991,23.991,0,0,1,28.532,4.278l-124,85.138a85.261,85.261,0,0,1-52.3,7.258L205.766,397.6a33.713,33.713,0,0,0-24.573,3.334c-.094.052-.187.108-.277.166L147.008,422.54l-24.2-79.385a54.627,54.627,0,0,0,20.164-11.375L155.187,320.9A76.617,76.617,0,0,1,234.731,313.261Z"
        {...pathProps}
      />
    </SvgIcon>
  );
}

WhatIcon.propTypes = {
  children: PropTypes.node,
  pathProps: PropTypes.object
};

export default WhatIcon;
