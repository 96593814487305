import React from "react";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

import { withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp, isWidthDown } from "@material-ui/core/withWidth";
import compose from "recompose/compose";
import CodeIcon from "@material-ui/icons/Code";
import AssessmentIcon from "@material-ui/icons/Assessment";
import SecurityIcon from "@material-ui/icons/Security";
// import PublicIcon from "@material-ui/icons/Public";
import TuneIcon from '@material-ui/icons/Tune';
import MultilineChartIcon from "@material-ui/icons/MultilineChart";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import FunctionsIcon from "@material-ui/icons/Functions";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import CloudIcon from "@material-ui/icons/Cloud";
// import TouchAppIcon from '@material-ui/icons/TouchApp';
// import { Gradient1, Gradient2 } from "../../shared/icon-gradient";

import {
  Selected1,
  Selected2,
  Selected3,
  Selected4,
  Selected5,
  Selected6,
  Selected7,
  Selected8,
  Selected9
} from "./expertise-content";
// import { CSSTransition } from "react-transition-group";
// import "./animations.css";

import "../../../App.css";

const styles = theme => ({
  iconBtn: {
    "&:hover,&:hover $customListIcon": {
      backgroundColor: "transparent"
    }
  },
  btnLabel: {
    flexDirection: "column"
  },
  customSelected: {
    backgroundColor: "inherit !important"
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
  },
  btnText: {
    margin: "1em",
    [theme.breakpoints.up("lg")]: {
      fontSize: ".65em"
    },
    [theme.breakpoints.down("md")]: {
      fontSize: ".6em"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: ".575em"
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: ".5em"
    }
  },
  customListIcon: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "50px"
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "40px"
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px"
    },
    color: "#6a6a6a",
    display: "inline-flex",
    minWidth: "0px",
    flexShrink: "0"
  },
  serviceText: {
    position: "relative",
    top: "-20px"
  },
  selectedBG: {
    // backgroundColor: "rgba(0,0,0,.15)"
    // style={{backgroundImage: 'linear-gradient(to bottom right, #439bfd, #ffffff)'}}
    //linear-gradient(to bottom right, red 0%, orange 10% 30%, yellow 50% 70%, green 90% 100%)
    backgroundImage:
      "linear-gradient(to bottom right, rgba(0,0,0,.1), transparent)"
  },
  gridContent: {
    justifyContent: "center",
    margin: "1rem 0",
  }
});

const gradient1 = "gradient1";
const gradient2 = "gradient2";

class ExpertiseGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover1: false,
      hover2: false,
      hover3: false,
      hover4: false,
      hover5: false,
      hover6: false,
      hover7: false,
      hover8: false,
      hover9: false
    };
    this.outputChange = this.outputChange.bind(this);
  }

  outputChange(val) {
    this.selected = val;
    this.props.selectedChange(this.selected);
  }

  render() {
    const {
      hover1,
      hover2,
      hover3,
      hover4,
      hover5,
      hover6,
      hover7,
      hover8,
      hover9
    } = this.state;
    const selected = this.props.selected;
    const { classes } = this.props;

    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Grid container item xs={12} spacing={1}>
          <Grid
            item
            xs={4}
            // style={{backgroundImage: 'linear-gradient(to bottom right, #439bfd, #ffffff)'}}
            className={`${classes.gridItem} ${
              selected === 1 && isWidthDown("sm", this.props.width)
                ? classes.selectedBG
                : null
            }`}
          >
            <IconButton
              disableTouchRipple={true}
              className={classes.iconBtn}
              classes={{ label: classes.btnLabel }}
              onMouseEnter={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover1: true });
                  this.outputChange(1);
                }
              }}
              onMouseLeave={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover1: false });
                }
              }}
              onClick={() => this.outputChange(1)}
            >
              <CodeIcon
                className={classes.customListIcon}
                component={svgProps => (
                  <svg {...svgProps}>
                    {React.cloneElement(svgProps.children[0], {
                      fill: `url(#${hover1 ? gradient2 : gradient1})`
                    })}
                  </svg>
                )}
              />
              <h6 className={`${classes.btnText} ${"Header-Blue"}`}>
                SOFTWARE ENGINEERING
              </h6>
            </IconButton>
          </Grid>
          <Grid
            item
            xs={4}
            className={`${classes.gridItem} ${
              selected === 2 && isWidthDown("sm", this.props.width)
                ? classes.selectedBG
                : null
            }`}
          >
            <IconButton
              disableTouchRipple={true}
              className={classes.iconBtn}
              classes={{ label: classes.btnLabel }}
              onMouseEnter={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover2: true });
                  this.outputChange(2);
                }
              }}
              onMouseLeave={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover2: false });
                }
              }}
              onClick={() => this.outputChange(2)}
            >
              <SupervisedUserCircleIcon
                className={classes.customListIcon}
                component={svgProps => (
                  <svg {...svgProps}>
                    {React.cloneElement(svgProps.children[0], {
                      fill: `url(#${hover2 ? gradient2 : gradient1})`
                    })}
                  </svg>
                )}
              />
              <h6 className={`${classes.btnText} ${"Header-Blue"}`}>
                LEADERSHIP
              </h6>
            </IconButton>
          </Grid>
          <Grid
            item
            xs={4}
            className={`${classes.gridItem} ${
              selected === 3 && isWidthDown("sm", this.props.width)
                ? classes.selectedBG
                : null
            }`}
          >
            <IconButton
              disableTouchRipple={true}
              className={classes.iconBtn}
              classes={{ label: classes.btnLabel }}
              onMouseEnter={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover3: true });
                  this.outputChange(3);
                }
              }}
              onMouseLeave={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover3: false });
                }
              }}
              onClick={() => this.outputChange(3)}
            >
              <SecurityIcon
                className={classes.customListIcon}
                component={svgProps => (
                  <svg {...svgProps}>
                    {React.cloneElement(svgProps.children[0], {
                      fill: `url(#${hover3 ? gradient2 : gradient1})`
                    })}
                  </svg>
                )}
              />
              <h6 className={`${classes.btnText} ${"Header-Blue"}`}>
                CYBERSECURITY
              </h6>
            </IconButton>
          </Grid>
        </Grid>
        {isWidthDown("sm", this.props.width) ? (
          <Grid
            className={classes.gridContent}
            container
            item
            xs={12}
            spacing={1}
          >
            {selected === 1 ? <Selected1 /> : null}
            {selected === 2 ? <Selected2 /> : null}
            {selected === 3 ? <Selected3 /> : null}
          </Grid>
        ) : null}
        <Grid container item xs={12} spacing={1}>
          <Grid
            item
            xs={4}
            className={`${classes.gridItem} ${
              selected === 4 && isWidthDown("sm", this.props.width)
                ? classes.selectedBG
                : null
            }`}
          >
            <IconButton
              disableTouchRipple={true}
              className={classes.iconBtn}
              classes={{ label: classes.btnLabel }}
              onMouseEnter={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover4: true });
                  this.outputChange(4);
                }
              }}
              onMouseLeave={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover4: false });
                }
              }}
              onClick={() => this.outputChange(4)}
            >
              <FunctionsIcon
                className={classes.customListIcon}
                component={svgProps => (
                  <svg {...svgProps}>
                    {React.cloneElement(svgProps.children[0], {
                      fill: `url(#${hover4 ? gradient2 : gradient1})`
                    })}
                  </svg>
                )}
              />
              <h6 className={`${classes.btnText} ${"Header-Blue"}`}>
                ALGORITHM DESIGN
              </h6>
            </IconButton>
          </Grid>
          <Grid
            item
            xs={4}
            className={`${classes.gridItem} ${
              selected === 5 && isWidthDown("sm", this.props.width)
                ? classes.selectedBG
                : null
            }`}
          >
            <IconButton
              disableTouchRipple={true}
              className={classes.iconBtn}
              classes={{ label: classes.btnLabel }}
              onMouseEnter={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover5: true });
                  this.outputChange(5);
                }
              }}
              onMouseLeave={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover5: false });
                }
              }}
              onClick={() => this.outputChange(5)}
            >
              <AssessmentIcon
                className={classes.customListIcon}
                component={svgProps => (
                  <svg {...svgProps}>
                    {React.cloneElement(svgProps.children[0], {
                      fill: `url(#${hover5 ? gradient2 : gradient1})`
                    })}
                  </svg>
                )}
              />
              <h6 className={`${classes.btnText} ${"Header-Blue"}`}>
                TEST AND AUTOMATION
              </h6>
            </IconButton>
          </Grid>
          <Grid
            item
            xs={4}
            className={`${classes.gridItem} ${
              selected === 6 && isWidthDown("sm", this.props.width)
                ? classes.selectedBG
                : null
            }`}
          >
            <IconButton
              disableTouchRipple={true}
              className={classes.iconBtn}
              classes={{ label: classes.btnLabel }}
              onMouseEnter={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover6: true });
                  this.outputChange(6);
                }
              }}
              onMouseLeave={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover6: false });
                }
              }}
              onClick={() => this.outputChange(6)}
            >
              <MultilineChartIcon
                className={classes.customListIcon}
                component={svgProps => (
                  <svg {...svgProps}>
                    {React.cloneElement(svgProps.children[0], {
                      fill: `url(#${hover6 ? gradient2 : gradient1})`
                    })}
                  </svg>
                )}
              />
              <h6 className={`${classes.btnText} ${"Header-Blue"}`}>
                DATA ANALYSIS
              </h6>
            </IconButton>
          </Grid>
        </Grid>
        {isWidthDown("sm", this.props.width) ? (
          <Grid
            className={classes.gridContent}
            container
            item
            xs={12}
            spacing={1}
          >
            {selected === 4 ? <Selected4 /> : null}
            {selected === 5 ? <Selected5 /> : null}
            {selected === 6 ? <Selected6 /> : null}
          </Grid>
        ) : null}
        <Grid container item xs={12} spacing={1}>
          <Grid
            item
            xs={4}
            className={`${classes.gridItem} ${
              selected === 7 && isWidthDown("sm", this.props.width)
                ? classes.selectedBG
                : null
            }`}
          >
            <IconButton
              disableTouchRipple={true}
              className={classes.iconBtn}
              classes={{ label: classes.btnLabel }}
              onMouseEnter={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover7: true });
                  this.outputChange(7);
                }
              }}
              onMouseLeave={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover7: false });
                }
              }}
              onClick={() => this.outputChange(7)}
            >
              <LaptopMacIcon
                className={classes.customListIcon}
                component={svgProps => (
                  <svg {...svgProps}>
                    {React.cloneElement(svgProps.children[0], {
                      fill: `url(#${hover7 ? gradient2 : gradient1})`
                    })}
                  </svg>
                )}
              />
              <h6 className={`${classes.btnText} ${"Header-Blue"}`}>
                SYSTEMS ENGINEERING
              </h6>
            </IconButton>
          </Grid>
          <Grid
            item
            xs={4}
            className={`${classes.gridItem} ${
              selected === 8 && isWidthDown("sm", this.props.width)
                ? classes.selectedBG
                : null
            }`}
          >
            <IconButton
              disableTouchRipple={true}
              className={classes.iconBtn}
              classes={{ label: classes.btnLabel }}
              onMouseEnter={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover8: true });
                  this.outputChange(8);
                }
              }}
              onMouseLeave={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover8: false });
                }
              }}
              onClick={() => this.outputChange(8)}
            >
              <CloudIcon
                className={classes.customListIcon}
                component={svgProps => (
                  <svg {...svgProps}>
                    {React.cloneElement(svgProps.children[0], {
                      fill: `url(#${hover8 ? gradient2 : gradient1})`
                    })}
                  </svg>
                )}
              />
              <h6 className={`${classes.btnText} ${"Header-Blue"}`}>DEVOPS</h6>
            </IconButton>
          </Grid>
          <Grid
            item
            xs={4}
            className={`${classes.gridItem} ${
              selected === 9 && isWidthDown("sm", this.props.width)
                ? classes.selectedBG
                : null
            }`}
          >
            <IconButton
              disableTouchRipple={true}
              className={classes.iconBtn}
              classes={{ label: classes.btnLabel }}
              onMouseEnter={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover9: true });
                  this.outputChange(9);
                }
              }}
              onMouseLeave={() => {
                if (isWidthUp("md", this.props.width)) {
                  this.setState({ hover9: false });
                }
              }}
              onClick={() => this.outputChange(9)}
            >
              <TuneIcon
                className={classes.customListIcon}
                component={svgProps => (
                  <svg {...svgProps}>
                    {React.cloneElement(svgProps.children[0], {
                      fill: `url(#${hover9 ? gradient2 : gradient1})`
                    })}
                  </svg>
                )}
              />
              <h6 className={`${classes.btnText} ${"Header-Blue"}`}>
                UI DESIGN
              </h6>
            </IconButton>
          </Grid>
        </Grid>
        {isWidthDown("sm", this.props.width) ? (
          <Grid
            className={classes.gridContent}
            container
            item
            xs={12}
            spacing={1}
          >
            {selected === 7 ? <Selected7 /> : null}
            {selected === 8 ? <Selected8 /> : null}
            {selected === 9 ? <Selected9 /> : null}
          </Grid>
        ) : null}
      </Grid>
    );
  }
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withWidth()
)(ExpertiseGrid);
